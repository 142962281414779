import React, { useEffect } from "react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, userlogout } from "src/Redux/UserSlice";

const TheHeaderDropdown = () => {
  const dispatch = useDispatch();
  const profilePhoto =
    useSelector((state) => state?.user?.profile?.data?.profile) || "";
  const Handlelogout = () => {
    dispatch(userlogout());
    localStorage.removeItem("userToken");
  };

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  return (
    <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          <CImg
            src={
              profilePhoto
                ? process.env.REACT_APP_API_URL + "/" + profilePhoto
                : "avatars/6.jpg"
            }
            className="c-avatar-img"
            alt="admin@bootstrapmaster.com"
          />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem header tag="div" color="light" className="text-center">
          <strong>Account</strong>
        </CDropdownItem>

        <CDropdownItem to="/admin/profile">
          <CIcon name="cil-user" className="mfe-2" />
          Profile
        </CDropdownItem>
        <CDropdownItem onClick={Handlelogout} to="/">
          <CIcon name="cil-user" className="mfe-2" /> Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default TheHeaderDropdown;
