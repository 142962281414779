import React , {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CCreateElement,
  CSidebar,
  
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,

  CCard,
  CCardBody,
 
} from '@coreui/react'
import CIcon from "@coreui/icons-react";
import logo from "../assets/logo/logo.png"



// sidebar nav config
import navigation, { navbar, useNavbar } from './_nav'
import { setSidebar } from 'src/Redux/sidebarSlice'
import { FetchNavbar } from 'src/Redux/UserSlice'
import { Link } from 'react-router-dom'

const TheSidebar = () => {
  const dispatch = useDispatch()
  const userToken = localStorage.getItem('userToken')
  
  const navItem =   useSelector((state)=> state?.user?.navbarData)
    if(navItem)
    {


      var dynemicNav =  navbar(navItem) ;
    
   
        
     
      
  
    }
  
  

    if(dynemicNav._children.length>0)
    {
      // navigation.unshift(dynemicNav)
      navigation.splice(2 , 1 , dynemicNav)
    }
 

   

  //  console.log(navAllItem)
  // const show = useSelector(state => state.sidebarShow)
  const show = useSelector((state) => state.sidebar.value);
   console.log("usertoken" , "theSiderbar" , userToken)

  useEffect(()=>{
    if(userToken)
    {
 
      dispatch(FetchNavbar(userToken))

    }
 },[])
  

  return (
    <CSidebar
      show={show}
      onShowChange={(val) =>{
        // dispatch({type: 'set', sidebarShow: val })
        // console.log("closed",val)
        dispatch(setSidebar(val))
      }}
    >
     
          <CCard color="white" className="text-white text-center">
            <CCardBody>
             <Link to="/admin/dashboard">
             <img src={logo}  alt='logo' />
             </Link>
       
       
            </CCardBody>
          </CCard>
        
     <CSidebarNav>
  
   
<CCreateElement
  items={navigation}
  components={{
    CSidebarNavDivider,
    CSidebarNavDropdown,
    CSidebarNavItem,
    CSidebarNavTitle
  }}
/>

</CSidebarNav>
     
      {/* <CSidebarMinimizer className="c-d-md-down-none"/> */}
    </CSidebar>
  )
}

 export default React.memo(TheSidebar)
