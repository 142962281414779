import React, { useEffect, useState } from "react";
import {
 
  CHeaderNavLink,
  CCard,
 
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
   
  CPagination,

  
  CButton,
 
  CRow,
} from "@coreui/react";
import { CSVLink } from "react-csv";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.min.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { getConfirmedOrder } from "src/Redux/orderSlice";
const { afterToday } =  DateRangePicker;




const GSTReport = ({status}) => {
  const [itemsPerPage, setItemsPerPage] = useState(5)
  const [page , setPage] = useState(1)
  const [filterDate ,setFilterDate] = useState()
  const [searchkey , setSearchKey] = useState('')
      
  const dispatch = useDispatch()
  const location = useLocation();

  const {  pathname  } = location;
  const history = useHistory()
  const order = useSelector((state)=>state?.order?.order?.allOrder) || []
  const totalpage = useSelector((state) => state?.order?.order?.totalPage);
  const fields = [
    { key: "SL_NO", label: "SL No", _style: { width: "10%" } },
    // { key: "productName", label: "Product Name", _style: { width: "25%" } },
    { key: "createdAt", label: "  Order Date  ", _style: { width: "200px" } },
    { key: "OrderId", label: "Order ID", _style: { width: "25%" } },
    { key: "paymentDate", label: "Payment Date", _style: { width: "10%" } },
    { key: "orderStatus", label: "Status", _style: { width: "10%" } },
    { key: "orderValue", label: "Amount", _style: { width: "15%" } },
    { key: "userName", label: "User Name", _style: { width: "15%" } },
    { key: "userEmail", label: "User Email", _style: { width: "15%" } },
    { key: "transactionId", label: "Transaction Id", _style: { width: "15%" } },
  ];



  const csvFields = [
    { key: "name", label: "Name", _style: { width: "20%" } },
    { key: "email", label: "Email", _style: { width: "15%" } },
    { key: "phone", label: "Number", _style: { width: "15%" } },
  ];


  let data = {
    page : page ,
  limits : itemsPerPage,
  
   }
  useEffect(()=>{
    
     if (status !== '') {
      data.status = status;
    }

    if(filterDate?.length===2)
    {
      filterDate[1].setHours(23)
      filterDate[1].setMinutes(59)
      filterDate[1].setSeconds(59)
      data.ltDate = new Date(filterDate[1])
      data.gtDate = filterDate[0].toDateString()
    }

   
  
   
  
    dispatch(getConfirmedOrder(data))
    
  },[itemsPerPage , page , filterDate  ])



  useEffect(() => {
    // Only proceed if searchkey is not empty
    if (status !== '') {
      data.status = status;
    }
    
    if (searchkey) {
    
  
  
      data.searchText = searchkey;

      if(filterDate?.length===2)
      {
        filterDate[1].setHours(23)
        filterDate[1].setMinutes(59)
        filterDate[1].setSeconds(59)
        data.ltDate = new Date(filterDate[1])
        data.gtDate = filterDate[0].toDateString()
      }
    
      // Set a timeout to dispatch after 2000 milliseconds (2 seconds)
      let timer = setTimeout(() => {
        dispatch(getConfirmedOrder(data))
      }, 2000);
  
      // Cleanup the previous timer on each searchkey change
      return () => clearTimeout(timer);
    }
  
    // If searchkey is empty, don't dispatch and clean up any existing timers
    dispatch(getConfirmedOrder(data))
    return () => {};
  
  }, [searchkey]);



  return (
    <div>
      <CRow>
        <CCol>
          <CCard style={{position:"relative"}}>
            <CCardHeader>
            <div className="d-flex justify-content-between">
                  <div>GST Report</div>
                  <div>
                    {order?.length > 0 ? (
                      <CSVLink
                        filename={"TableContent.csv"}
                        data={order}
                        headers={csvFields}
                        style={{ listStyle: "none" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 512 512"
                        >
                          <path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V304H176c-35.3 0-64 28.7-64 64V512H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM200 352h16c22.1 0 40 17.9 40 40v8c0 8.8-7.2 16-16 16s-16-7.2-16-16v-8c0-4.4-3.6-8-8-8H200c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-8c0-8.8 7.2-16 16-16s16 7.2 16 16v8c0 22.1-17.9 40-40 40H200c-22.1 0-40-17.9-40-40V392c0-22.1 17.9-40 40-40zm133.1 0H368c8.8 0 16 7.2 16 16s-7.2 16-16 16H333.1c-7.2 0-13.1 5.9-13.1 13.1c0 5.2 3 9.9 7.8 12l37.4 16.6c16.3 7.2 26.8 23.4 26.8 41.2c0 24.9-20.2 45.1-45.1 45.1H304c-8.8 0-16-7.2-16-16s7.2-16 16-16h42.9c7.2 0 13.1-5.9 13.1-13.1c0-5.2-3-9.9-7.8-12l-37.4-16.6c-16.3-7.2-26.8-23.4-26.8-41.2c0-24.9 20.2-45.1 45.1-45.1zm98.9 0c8.8 0 16 7.2 16 16v31.6c0 23 5.5 45.6 16 66c10.5-20.3 16-42.9 16-66V368c0-8.8 7.2-16 16-16s16 7.2 16 16v31.6c0 34.7-10.3 68.7-29.6 97.6l-5.1 7.7c-3 4.5-8 7.1-13.3 7.1s-10.3-2.7-13.3-7.1l-5.1-7.7c-19.3-28.9-29.6-62.9-29.6-97.6V368c0-8.8 7.2-16 16-16z" />
                        </svg>
                      </CSVLink>
                     ) : null} 
                  </div>
                </div>
           
              
            
            </CCardHeader>
            
            <CCardBody>
            <div style={{position:"absolute", top:"84px",left:"18rem"}}>
                <DateRangePicker format="yyyy-MM-dd" showOneCalendar  placeholder="Select Date Range" value={filterDate} onChange={(e)=>setFilterDate(e)} disabledDate={afterToday()}/>
              </div>
              <CDataTable
                // key={randomValue}
                items={order}
                fields={fields}
                columnFilter={false}
                tableFilter
                footer={false}
                itemsPerPageSelect
                itemsPerPage={itemsPerPage}
                hover
                sorter
                onPaginationChange={(e)=> setItemsPerPage(e)}
                onTableFilterChange={(item)=>{
                     setSearchKey(item)
                }}
             
              
                scopedSlots={{
                  SL_NO: (item, index) => {
                    return <td className="py-2">{(page - 1) * itemsPerPage + index + 1}</td>;
                  },
                  // productName : (item , index)=>{
                  //    return (
                  //     <td style={{minWidth:'150px'}}>{item?.product[0]?.productName}</td>
                  //    )
                  // },
                  createdAt : (item , index)=>{
                    let date = moment(item.createdAt).format('ll')
                     return (
                      <td style={{minWidth:'150px'}}>{date}</td>
                     )
                  },
                  OrderId : (item , index)=>{
                   
                    return (
                     <td style={{minWidth:'250px'}}>{item.OrderId}</td>
                    )
                 },

                  paymentDate : (item , index)=>{
                    let date = moment(item.paymentDate).format('ll')
                     return (
                      <td style={{minWidth:'150px'}}>{date}</td>
                     )
                  },
                  userName : (item , index)=>{
                   
                     return (
                      <td style={{minWidth:'150px'}}>{item.userName}</td>
                     )
                  },
            
                
                }}
              />
               <CPagination
               pages={totalpage}
              activePage={page}
              onActivePageChange={(i) => setPage(i)}
        />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
};

export default GSTReport;

GSTReport.defaultProps  = {
  status: '',
};

