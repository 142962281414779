import React, { useEffect, useState } from "react";
import {
  CHeaderNavLink,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CButton,
  CRow,
} from "@coreui/react";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.min.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { getConfirmedOrder } from "src/Redux/orderSlice";
const { afterToday } = DateRangePicker;

const AllOrder = ({ status }) => {
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [filterDate, setFilterDate] = useState();
  const [searchkey, setSearchKey] = useState("");

  const dispatch = useDispatch();
  const location = useLocation();

  const { pathname } = location;
  const history = useHistory();
  const order = useSelector((state) => state?.order?.order?.allOrder) || [];
  const totalpage = useSelector((state) => state?.order?.order?.totalPage);
  const fields = [
    { key: "SL_NO", label: "SL No", _style: { width: "10%" } },
    // { key: "productName", label: "Product Name", _style: { width: "25%" } },
    { key: "createdAt", label: "  Order Date  ", _style: { width: "200px" } },
    { key: "OrderId", label: "Order ID", _style: { width: "25%" } },
    { key: "paymentType", label: "Order Method", _style: { width: "10%" } },

    { key: "orderStatus", label: "Status", _style: { width: "10%" } },
    { key: "orderValue", label: "Amount", _style: { width: "15%" } },
    { key: "userName", label: "User Name", _style: { width: "15%" } },
    { key: "userEmail", label: "User Email", _style: { width: "15%" } },
    { key: "paymentDate", label: "Payment Date", _style: { width: "10%" } },
    { key: "transactionId", label: "Transaction Id", _style: { width: "15%" } },
    {
      key: "show_details",
      label: "Action",
      _style: { width: "5%" },
      sorter: false,
      filter: false,
    },
  ];

  let data = {
    page: page,
    limits: itemsPerPage,
  };
  useEffect(() => {
    if (status !== "") {
      data.status = status;
    }

    if (filterDate?.length === 2) {
      filterDate[1].setHours(23);
      filterDate[1].setMinutes(59);
      filterDate[1].setSeconds(59);
      data.ltDate = new Date(filterDate[1]);
      data.gtDate = filterDate[0].toDateString();
    }

    dispatch(getConfirmedOrder(data));
  }, [itemsPerPage, page, filterDate]);

  useEffect(() => {
    // Only proceed if searchkey is not empty
    if (status !== "") {
      data.status = status;
    }

    if (searchkey) {
      data.searchText = searchkey;

      if (filterDate?.length === 2) {
        filterDate[1].setHours(23);
        filterDate[1].setMinutes(59);
        filterDate[1].setSeconds(59);
        data.ltDate = new Date(filterDate[1]);
        data.gtDate = filterDate[0].toDateString();
      }

      // Set a timeout to dispatch after 2000 milliseconds (2 seconds)
      let timer = setTimeout(() => {
        dispatch(getConfirmedOrder(data));
      }, 2000);

      // Cleanup the previous timer on each searchkey change
      return () => clearTimeout(timer);
    }

    // If searchkey is empty, don't dispatch and clean up any existing timers
    dispatch(getConfirmedOrder(data));
    return () => {};
  }, [searchkey]);

  const OrderDetail = (item) => {
    console.log(item);
    history.push({
      pathname: "/admin/order",
    });
    localStorage.setItem("location", pathname);
    localStorage.setItem("id", item._id);
  };

  return (
    <div>
      <CRow>
        <CCol>
          <CCard style={{ position: "relative" }}>
            <CCardHeader>Order List</CCardHeader>

            <CCardBody>
              <div style={{ position: "absolute", top: "71px", left: "18rem" }}>
                <DateRangePicker
                  format="yyyy-MM-dd"
                  showOneCalendar
                  placeholder="Select Date Range"
                  value={filterDate}
                  onChange={(e) => setFilterDate(e)}
                  disabledDate={afterToday()}
                />
              </div>
              <CDataTable
                // key={randomValue}
                items={order}
                fields={fields}
                columnFilter={false}
                tableFilter
                footer={false}
                itemsPerPageSelect
                itemsPerPage={itemsPerPage}
                hover
                sorter
                onPaginationChange={(e) => setItemsPerPage(e)}
                onTableFilterChange={(item) => {
                  setSearchKey(item);
                }}
                scopedSlots={{
                  SL_NO: (item, index) => {
                    return (
                      <td className="py-2">
                        {(page - 1) * itemsPerPage + index + 1}
                      </td>
                    );
                  },
                  // productName : (item , index)=>{
                  //    return (
                  //     <td style={{minWidth:'150px'}}>{item?.product[0]?.productName}</td>
                  //    )
                  // },
                  createdAt: (item, index) => {
                    let date = moment(item.createdAt).format("ll");
                    return <td style={{ minWidth: "150px" }}>{date}</td>;
                  },
                  OrderId: (item, index) => {
                    return (
                      <td style={{ minWidth: "250px" }}>{item.OrderId}</td>
                    );
                  },

                  paymentType: (item, index) => {
                    return (
                      <td style={{ minWidth: "250px" }}>
                        {item.paymentType === "cashOnDelivery"
                          ? "COD"
                          : "Prepaid"}
                      </td>
                    );
                  },

                  paymentDate: (item, index) => {
                    let date =
                      item.paymentDate &&
                      moment(item?.paymentDate).format("ll");
                    return <td style={{ minWidth: "150px" }}>{date}</td>;
                  },
                  userName: (item, index) => {
                    return (
                      <td style={{ minWidth: "150px" }}>{item.userName}</td>
                    );
                  },
                  show_details: (item, index) => {
                    return (
                      <td className="py-2">
                        <CButton
                          color="primary"
                          // variant="outlinPlee"
                          shape="square"
                          size="sm"
                          onClick={() => OrderDetail(item)}
                        >
                          View
                          {/* <CHeaderNavLink to="/admin/order">View</CHeaderNavLink> */}
                        </CButton>
                      </td>
                    );
                  },
                }}
              />
              <CPagination
                pages={totalpage}
                activePage={page}
                onActivePageChange={(i) => setPage(i)}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
};

export default AllOrder;

AllOrder.defaultProps = {
  status: "",
};
