import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../http-common";

export const addWayBillNumberAPI = createAsyncThunk(
  "login/addWayBillNumberAPI",
  async (initialPost) => {
    try {
      const res = await API.post(
        `admin/addWaybill?waybill=${initialPost.waybillNumber}&_id=${initialPost.orderId}`
      );
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getConfirmedOrder = createAsyncThunk(
  "login/getConfirmedOrder",
  async (initialPost) => {
    //  console.log(initialPost)
    // let page = initialPost?.page
    // let limits = initialPost?.limits
    //  let status = initialPost?.status
    //  let text = initialPost?.searchText
    //  let gtDate = initialPost?.gtDate
    //  let ltDate = initialPost?.ltDate
    //  let url = ''
    const baseUrl = "admin/getOrderList";
    const queryParams = new URLSearchParams();
    // Add orderStatus if it exists
    if (initialPost && initialPost.status !== undefined) {
      queryParams.append("orderStatus", initialPost.status);
    }
    // Add limit if it exists
    if (initialPost && initialPost.limits !== undefined) {
      queryParams.append("limit", initialPost.limits);
    }
    // Add pageNo (page) if it exists
    if (initialPost && initialPost.page !== undefined) {
      queryParams.append("pageNo", initialPost.page);
    }
    if (initialPost && initialPost.searchText !== undefined) {
      queryParams.append("searchText", initialPost.searchText);
    }
    // Add gtDate if it exists
    if (initialPost && initialPost.gtDate !== undefined) {
      queryParams.append("gtDate", initialPost.gtDate);
    }
    // Add ltDate if it exists
    if (initialPost && initialPost.ltDate !== undefined) {
      queryParams.append("ltDate", initialPost.ltDate);
    }
    try {
      const res = await API.get(`${baseUrl}?${queryParams.toString()}`);
      return res.data;
    } catch (error) {
      return initialPost.rejectWithValue(error.response.data);
    }
  }
);

export const getOrderDetails = createAsyncThunk(
  "login/getOrderDetails",
  async (initialPost) => {
    try {
      const res = await API.get(`admin/getOrder?id=${initialPost.id}`);
      return res.data;
    } catch (error) {
      return initialPost.rejectWithValue(error.response.data);
    }
  }
);

export const getUserSupport = createAsyncThunk(
  "login/getUserSupport",
  async (initialPost) => {
    const baseUrl = `admin/getAllSupport`;
    const queryParams = new URLSearchParams();

    if (initialPost && initialPost.limits !== undefined) {
      queryParams.append("limit", initialPost.limits);
    }
    if (initialPost && initialPost.page !== undefined) {
      queryParams.append("pageNo", initialPost.page);
    }
    if (initialPost && initialPost.searchText !== undefined) {
      queryParams.append("searchText", initialPost.searchText);
    }
    if (initialPost && initialPost.gtDate !== undefined) {
      queryParams.append("gtDate", initialPost.gtDate);
    }
    // Add ltDate if it exists
    if (initialPost && initialPost.ltDate !== undefined) {
      queryParams.append("ltDate", initialPost.ltDate);
    }

    try {
      const res = await API.get(`${baseUrl}?${queryParams.toString()}`);
      return res.data;
    } catch (error) {
      return initialPost.rejectWithValue(error.response.data);
    }
  }
);

export const getRatingApi = createAsyncThunk(
  "login/getRatingApi",
  async (initialPost) => {
    const baseUrl = `admin/getReview`;
    const queryParams = new URLSearchParams();

    if (initialPost && initialPost.limits !== undefined) {
      queryParams.append("limit", initialPost.limits);
    }
    if (initialPost && initialPost.page !== undefined) {
      queryParams.append("pageNo", initialPost.page);
    }
    if (initialPost && initialPost.searchText !== undefined) {
      queryParams.append("searchText", initialPost.searchText);
    }
    if (initialPost && initialPost.gtDate !== undefined) {
      queryParams.append("gtDate", initialPost.gtDate);
    }
    // Add ltDate if it exists
    if (initialPost && initialPost.ltDate !== undefined) {
      queryParams.append("ltDate", initialPost.ltDate);
    }

    try {
      const res = await API.get(`${baseUrl}?${queryParams.toString()}`);
      return res.data;
    } catch (error) {
      return initialPost.rejectWithValue(error.response.data);
    }
  }
);

export const RatingApiUpdateStatus = createAsyncThunk(
  "login/RatingApiUpdateStatus",
  async (initialPost) => {
    try {
      const res = await API.put(`admin/reviewStatusUpdate`, initialPost);
      alert(res.data.message);
      return res.data;
    } catch (error) {
      return initialPost.rejectWithValue(error.response.data);
    }
  }
);

export const RatingApiDelete = createAsyncThunk(
  "login/RatingApiUpdateStatus",
  async (initialPost) => {
    try {
      const res = await API.deleteData(
        `admin/reviewDelete/${initialPost.id}`,
        initialPost
      );
      alert(res.data.message);
      return res.data;
    } catch (error) {
      return initialPost.rejectWithValue(error.response.data);
    }
  }
);

export const supportUpdateStatus = createAsyncThunk(
  "login/supportUpdateStatus",
  async (initialPost) => {
    try {
      const res = await API.put(`admin/updateSupport`, initialPost);
      return res.data;
    } catch (error) {
      return initialPost.rejectWithValue(error.response.data);
    }
  }
);

export const getHighDemandProduct = createAsyncThunk(
  "login/getHighDemandProduct",
  async (initialPost) => {
    const baseUrl = `admin/getDemandProduct`;
    const queryParams = new URLSearchParams();
    if (initialPost && initialPost.gtDate !== undefined) {
      queryParams.append("gtDate", initialPost.gtDate);
    }
    // Add ltDate if it exists
    if (initialPost && initialPost.ltDate !== undefined) {
      queryParams.append("ltDate", initialPost.ltDate);
    }

    try {
      const res = await API.get(`${baseUrl}?${queryParams.toString()}`);
      return res.data;
    } catch (error) {
      return initialPost.rejectWithValue(error.response.data);
    }
  }
);

export const bulkCancelProduct = createAsyncThunk(
  "bulkcancel",
  async (initialPost) => {
    try {
      const res = await API.get(
        `admin/OredrCancel?_id=${initialPost.id}&orderStatus=CANCEL`
      );
      alert(res.data.message);
      return res.data;
    } catch (error) {
      alert(error.response.data.message);
      return initialPost.rejectWithValue(error.response.data);
    }
  }
);

export const singleCancelProduct = createAsyncThunk(
  "singlecancel",
  async (initialPost) => {
    const baseUrl = `admin/OredrProdCancel`;
    const queryParams = new URLSearchParams();
    if (initialPost && initialPost.id !== undefined) {
      queryParams.append("_id", initialPost.id);
    }
    if (initialPost && initialPost.remarks !== undefined) {
      queryParams.append("remarks", initialPost.remarks);
    }
    if (initialPost && initialPost.refundAmount !== undefined) {
      queryParams.append("refundAmount", initialPost.refundAmount);
    }
    if (initialPost && initialPost.status !== undefined) {
      queryParams.append("orderStatus", initialPost.status);
    }
    try {
      const res = await API.get(`${baseUrl}?${queryParams.toString()}`);
      alert(res.data.message);
      return res.data;
    } catch (error) {
      alert(error.response.data.message);
      return initialPost.rejectWithValue(error.response.data);
    }
  }
);

export const deliveredOrder = createAsyncThunk(
  "deliveredOrder",
  async (initialPost) => {
    try {
      const res = await API.get(`admin/OrderDelivery?_id=${initialPost.id}`);
      // alert(res.data.message);
      return res.data;
    } catch (error) {
      // alert(error.response.data.message);
      return error.response.data;
    }
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    order: {},
    orderDetails: [],
    support: [],
    rating: [],
    demand: [],
  },

  extraReducers: (builder) => {
    builder.addCase(getHighDemandProduct.fulfilled, (state, action) => {
      state.demand = action.payload;
    });

    builder.addCase(getConfirmedOrder.fulfilled, (state, action) => {
      state.order = action.payload;
    });

    builder.addCase(getUserSupport.fulfilled, (state, action) => {
      state.support = action.payload;
    });
    builder.addCase(getRatingApi.fulfilled, (state, action) => {
      state.rating = action.payload;
    });

    builder.addCase(getConfirmedOrder.pending, (state, action) => {
      state.order = {};
    });

    builder.addCase(getOrderDetails.fulfilled, (state, action) => {
      state.orderDetails = action.payload;
    });
    builder.addCase(getConfirmedOrder.rejected, (state, action) => {
      state.order = {};
    });

    builder.addCase(getOrderDetails.rejected, (state, action) => {
      state.orderDetails = [];
    });

    builder.addCase(getUserSupport.rejected, (state, action) => {
      state.support = [];
    });
    builder.addCase(getRatingApi.rejected, (state, action) => {
      state.rating = [];
    });
  },
});

export default orderSlice.reducer;
