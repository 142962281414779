import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: 'responsive',
}

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSidebar: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setSidebar } = sidebarSlice.actions

export default sidebarSlice.reducer