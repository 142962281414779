import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  DeleteProduct,
  DeleteProductAllProductByCat,
  fetchproductbycat,
  searchProduct,
} from "src/Redux/UserSlice";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
} from "@coreui/react";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { Circles } from "react-loader-spinner";
import { AiFillDelete } from "react-icons/ai";
import EditSingleProduct from "./EditAllProductbyCategory/EditSingleProduct";

const AllProductByCategory = () => {
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [loading, setloading] = useState(false);
  const [editPage, setEditPage] = useState(false);
  const [parentSku, setparentSku] = useState("");
  const [searchkey, setSearchKey] = useState("");
  const [searchedProduct, setSearchedProduct] = useState([]);
  const dispatch = useDispatch();
  let { cat, subcat } = useParams();

  const ProductData = useSelector(
    (state) => state?.user?.ProductbyCat?.findProduct || []
  );
  const totalpage = useSelector(
    (state) => state?.user?.ProductbyCat?.totalPages
  );
  const totalLengthOfProduct = useSelector(
    (state) => state?.user?.ProductbyCat?.totalproduct
  );
  let data = {
    link: cat,
    subcat: subcat || "",
    page: page,
    limits: itemsPerPage,
  };

  useEffect(() => {
    setloading(false);
    setEditPage(false);
    setPage(1);
    dispatch(fetchproductbycat(data));

    setTimeout(() => {
      setloading(true);
    }, 500);
  }, [cat, subcat, itemsPerPage]);

  useEffect(() => {
    setloading(false);
    dispatch(fetchproductbycat(data)).then(() => setloading(true));

    setTimeout(() => {
      setloading(true);
    }, 500);
  }, [page]);

  const fields = [
    { key: "SL_NO", label: "SL No", _style: { width: "10%" } },
    { key: "image", label: "Image", _style: { width: "10%" } },
    // { key: "PName", label: "Product Name", _style: { width: "25%" } },
    { key: "productName", label: "Product Name", _style: { width: "15%" } },
    { key: "productType", label: "Product Type", _style: { width: "15%" } },
    // { key: "HSN", label: "HSN CODE", _style: { width: "10%" } },
    { key: "FeatureProduct", label: "Feature Status", _style: { width: "5%" } },
    { key: "parentSku", label: "Parent sku", _style: { width: "10%" } },

    { key: "Price", label: "Price", _style: { width: "10%" } },

    { key: "View", label: "Edit", _style: { width: "15%" } },
  ];

  const handleEdit = (id) => {
    setEditPage(true);
    setparentSku(id);
  };

  const handleDelete = (id) => {
    const choice = window.confirm("Are you sure you want to delete Data?");
    if (choice) {
      setEditPage(false);
      dispatch(DeleteProduct(id));
      setloading(false);

      dispatch(fetchproductbycat(data)).then(() => setloading(true));
    }
  };

  useEffect(() => {
    if (searchkey && searchkey?.length > 2) {
      let data = {
        link: cat,
        subcat: subcat || "",
        searchkey: searchkey,
      };
      const getdata = setTimeout(() => {
        dispatch(searchProduct(data)).then((res) => {
          if (res.payload.success === true) {
            setSearchedProduct(res.payload.findProduct);
          }
        });
      }, 2000);
      return () => clearTimeout(getdata);
    }
  }, [searchkey]);

  const handleDeleteAllData = () => {
    const choice = window.confirm("Are you sure you want to delete All Data?");

    if (choice) {
      setEditPage(false);
      let body = {
        cat: cat,
        subcat: subcat || "",
      };

      dispatch(DeleteProductAllProductByCat(body));
      setloading(false);

      dispatch(fetchproductbycat(data)).then(() => setloading(true));
    }
  };

  return editPage ? (
    <EditSingleProduct setEditPage={setEditPage} parentSku={parentSku} />
  ) : (
    <>
      {loading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 20px",
            }}
          >
            <p>
              {subcat ? subcat : cat} <span>{totalLengthOfProduct}</span>{" "}
            </p>
            <div>
              <CButton
                color="success"
                className="mr-1"
                onClick={handleDeleteAllData}
              >
                Delete All Products
              </CButton>
            </div>
          </div>

          <CCardBody style={{ textAlign: "center" }}>
            <CDataTable
              items={
                searchedProduct.length >= 1 ? searchedProduct : ProductData
              }
              fields={fields}
              columnFilter={false}
              tableFilter
              footer={false}
              itemsPerPageSelect
              itemsPerPage={itemsPerPage}
              hover
              sorter
              // onPagesChange={(e)=> console.log(e)}
              onPaginationChange={(e) => setItemsPerPage(e)}
              // onPageChange={(e)=> console.log(e)}

              onTableFilterChange={(item) => {
                setSearchKey(item);
                setSearchedProduct([]);
              }}
              scopedSlots={{
                SL_NO: (item, index) => {
                  return (
                    <td className="py-2">
                      {(page - 1) * itemsPerPage + index + 1}
                    </td>
                  );
                },
                productType: (data) => {
                  return <td>{data?.productType}</td>;
                },
                productName: (data) => {
                  return <td>{data?.productName}</td>;
                },
                parentSku: (data) => {
                  return <td>{data?.parentSku}</td>;
                },
                FeatureProduct: (data) => {
                  return <td>{data?.featureProduct ? "Yes" : "No"}</td>;
                },
                Price: (data) => {
                  return <td>{data?.PriceArray[0]?.Final_price}</td>;
                },
                image: (data) => {
                  return (
                    <td>
                      <img
                        src={data?.image1}
                        height={50}
                        width={50}
                        alt="Image"
                      />
                    </td>
                  );
                },
                View: (data, index) => {
                  return (
                    <td className="py-2">
                      <CButton
                        // color="primary"
                        // variant="outlinPlee"
                        shape="square"
                        size="sm"
                      >
                        <div className="d-flex gap-3">
                          {/* <GrView
                      size={25}
                      
                    /> */}
                          <FiEdit
                            size={25}
                            onClick={() => handleEdit(data.parentSku)}
                          />

                          <AiFillDelete
                            size={25}
                            onClick={() => handleDelete(data._id)}
                          />
                        </div>
                      </CButton>
                    </td>
                  );
                },
              }}
            />
            <CPagination
              pages={totalpage}
              activePage={page}
              onActivePageChange={(i) => setPage(i)}
            />
          </CCardBody>
        </>
      ) : (
        <div
          style={{ display: "flex", justifyContent: "center", padding: "20px" }}
        >
          <Circles
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
    </>
  );
};

export default AllProductByCategory;
