import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { GetAllUserDiscount, UserDiscount } from "src/Redux/UserSlice";
import {
  CButton,
  CCol,
  CFormGroup,
  CInput, 
  CLabel,
  CSelect,
} from "@coreui/react";
import "../../../App.css"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddDiamondTypeUserCoupon = ({ success, setSuccess }) => {
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [endDate, setEndDate] = useState(dayjs().add(7, "day"));
  const labels = [
    "All Diamond",
    "EF-VVS",
    "GH-VVS",
    "GH-VS",
    "GH-SI",
    "IJ-SI",
    "GH-I2",
  ];

  const initialValues = labels.map((label, index) => ({
    label,
    value: index === 0,
  }));
  const [checkedValues, setCheckedValues] = useState(initialValues);

  const [termsandcondition, settermsandcondition] = useState([
    {
      text: "",
    },
  ]);
  const [data, setdata] = useState({
    tittle: "",

    startDate: "",
    endDate: "",
    productcategory: "",
    productSubcat: "",
    discountName: "",
    DiscountType: "",
    DiscountValue: "",
    remark: "",
  });

  const handleClose = () => {
    setSuccess(false);
  };

  const HandleChange = (e) => {
    const Newinput = (data) => ({ ...data, [e.target.name]: e.target.value });
    setdata(Newinput);
  };
  const HandleAddd = (evnt) => {

    evnt.preventDefault();
    const trueValuesArray = checkedValues.filter(diamond => diamond.value === true && diamond.label !== 'All Diamond').map(diamond => diamond.label);
    const post = {
      discountName: data.discountName,
      discountType: data.DiscountType,
      discountValue: +data.DiscountValue,
      startDate: startDate,
      userEmail:data.userEmail,
      endDate: endDate,
      diamondType: trueValuesArray,
      TandC: termsandcondition,
      title: data.tittle,
      type: "ByDiamond",
    };

    if(data.DiscountType === 'percentage')
    {
      post.uptoValue = +data.uptoValue
    }


   
    const checkEmptyInput = !Object.values(post).every(
      (res) => res === "" || res === 0
    );

    if (checkEmptyInput && termsandcondition.length > 0) {

      dispatch(UserDiscount(post)).then(async()=>
      {
      //  await dispatch(GetAllGeneralDiscount({ type: "ByDiamond" }));
    
       setTimeout(()=>{
       dispatch(GetAllUserDiscount({ type: "ByDiamond" }));
       setSuccess(false);
      },2000)
      });


    } else {
      alert("Please Filled all fields");
    }
  };

  const handleTermsandCondition = (index, newText) => {
    // Create a new array with the updated text
    const updatedTerms = [...termsandcondition];
    updatedTerms[index] = { text: newText };

    // Update the state with the new array
    settermsandcondition(updatedTerms);
  };

  const handleAddField = () => {
    settermsandcondition([...termsandcondition, { text: "" }]);
  };

  const handleRemoveField = (index) => {
    const updatedTerms = [...termsandcondition];
    updatedTerms.splice(index, 1);
    settermsandcondition(updatedTerms);
  };

  const handleChange = (index) => (event) => {
    const newCheckedValues = [...checkedValues];
    newCheckedValues[index].value = event.target.checked;
    setCheckedValues(newCheckedValues);
  };

  console.log(checkedValues);

  return (
    <div>
      <Dialog
        open={success}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
              padding: "9px", // Set your width here
            },
          },
        }}
      >
        <DialogTitle>Add Coupan</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="mt-2">
              <CLabel className="m-1">Discount Title</CLabel>
              <CInput
                type="text"
                name="tittle"
                value={data.tittle}
                onChange={HandleChange}
              ></CInput>
            </div>
            <div className="mt-2">
              <FormControlLabel
                label={labels[0]}
                control={
                  <Checkbox
                    checked={checkedValues.every(({ value }) => value)}
                    indeterminate={
                      checkedValues.some(({ value }) => value) &&
                      !checkedValues.every(({ value }) => value)
                    }
                    onChange={(event) =>
                      setCheckedValues(
                        checkedValues.map(({ label }) => ({
                          label,
                          value: event.target.checked,
                        }))
                      )
                    }
                  />
                }
              />
              <Box sx={{ display: "flex", flexDirection: "row", ml: 3 }}>
                {checkedValues.slice(1).map(({ label, value }, index) => (
                  <FormControlLabel
                    key={index}
                    label={label}
                    control={
                      <Checkbox
                        checked={value}
                        onChange={handleChange(index + 1)}
                      />
                    }
                  />
                ))}
              </Box>
            </div>
            <div>
              <CLabel className="m-1">Discount Code</CLabel>
              <CInput
                onChange={HandleChange}
                name="discountName"
                value={data.discountName}
              ></CInput>
            </div>

            <div className="Type">
              <label className="m-1">Type</label>
              <br />
              <input
                type="radio"
                id="percentage"
                name="DiscountType"
                onChange={HandleChange}
                value="percentage"
              />
              <label htmlFor="percentage" style={{ margin: "10px" }}>
                Percentage
              </label>

              <input
                type="radio"
                id="Amount"
                name="DiscountType"
                value="flat"
                onChange={HandleChange}
              />
              <label htmlFor="Amount" style={{ margin: "10px" }}>
                Amount
              </label>
            </div>
            <div className="mt-2">
              <CLabel className="m-1">Email</CLabel>
              <CInput
                type="text"
                name="userEmail"
                value={data.userEmail}
                onChange={HandleChange}
              ></CInput>
            </div>
          
           
            <div className="mt-2">
              <CLabel className="m-1">Discount value</CLabel>
              <CInput
                type="number"
                name="DiscountValue"
                value={data.DiscountValue}
                onChange={HandleChange}
              ></CInput>
            </div>

            {data?.DiscountType ==='percentage' ?   
            <div className="mt-2">
              <CLabel className="m-1">Upto value</CLabel>
              <CInput
                type="number"
                name="uptoValue"
                value={data.uptoValue}
                onChange={HandleChange}
              ></CInput>
            </div> :null }

            <div>
              <CLabel className="m-0 mt-2">Terms and Condition</CLabel>
              {termsandcondition.map((data, index) => (
                <div key={index} className="input-group mt-1">
                  <CInput
                    type="text"
                    name={`terms-${index}`}
                    value={data.text}
                    onChange={(e) =>
                      handleTermsandCondition(index, e.target.value)
                    }
                    className="form-control"
                  />
                  {index !== termsandcondition.length - 1 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={() => handleRemoveField(index)}
                    >
                      Remove
                    </button>
                  )}
                </div>
              ))}
              <button
                type="button"
                className="btn btn-outline-primary mt-2"
                onClick={handleAddField}
              >
                Add Field
              </button>
             
                
            </div>

            <div className="mt-2">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    sx={{
                      ".MuiOutlinedInput-input": {
                        height: "0.5rem",
                      },
                    }}
                    onChange={(newValue) => setStartDate(newValue)}
                  />
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    sx={{
                      ".MuiOutlinedInput-input": {
                        height: "0.5rem",
                      },
                    }}
                    onChange={(newValue) => setEndDate(newValue)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <CButton
                  color="success"
                style={{
                  border: "1px solid black",
                  margin: "20px",
                  fontSize: "17px",
                  padding: '5px 40px'
                
                }}
                onClick={HandleAddd}
              >
                Add{" "}
              </CButton>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddDiamondTypeUserCoupon;
