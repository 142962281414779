import React from "react";
import { CFooter } from "@coreui/react";

const TheFooter = () => {
  return (
    <CFooter fixed={false}>
      <div>
        <a style={{ color: "black" }} target="_blank" rel="noopener noreferrer">
          Caratglitz
        </a>
        <span className="ml-1">&copy; {new Date().getFullYear()}.</span>
      </div>
      <div className="mfs-auto">
        <span className="mr-1">Powered by</span>
        <a
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#3c4b64" }}
          href="https://xaltam.com/"
        >
          Xaltam
        </a>
      </div>
    </CFooter>
  );
};

export default React.memo(TheFooter);
