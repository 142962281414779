import React from 'react'
import { Navigate } from 'react-router'
import { TheLayout } from 'src/containers'


const PrivateRoute = ({ children, isAuthenticated,component:component, ...rest }) => {
  const token =localStorage.getItem("userToken")
  return token ? <TheLayout/> : <Navigate to="/" />
}

export default PrivateRoute