import { configureStore,} from "@reduxjs/toolkit";
import AdminSlice from "./UserSlice"
import sidebarSlice from "./sidebarSlice";
import orderSlice from "./orderSlice";

const store = configureStore({
    reducer:{
        user : AdminSlice,
        sidebar:sidebarSlice,
        order : orderSlice
 },
    
})

export default store;

