import React from "react";
import AllProductByCategory from "./views/base/AllProductByCategory/AllProductByCategory";
import ListProductBasedCoupon from "./views/base/Coupan/ListProductBasedCoupon";
import UserAllDiscount from "./views/base/Coupan/UserAllDiscount";
import ListAllGeneralCoupon from "./views/base/GeneralCoupon/AllGereralCoupon/ListAllGeneralCoupon";
import ListProductBasedGeneralCoupon from "./views/base/GeneralCoupon/ProductBasedGereralCoupon/ListProductBasedGerneralCoupon";
import ListCategoryBasedGeneralCoupon from "./views/base/GeneralCoupon/CategoryBaseGeneralCoupon/ListCategoryBasedGeneralCoupon";
import ListDiamondCoupon from "./views/base/GeneralCoupon/DiamondCoupon/ListDiamondCoupon";
import ListDiamondTypeUserCoupon from "./views/base/Coupan/ListDiamondTypeUserCoupon";
import TransitOrder from "./views/base/OrderControl/TransitOrder/TransitOrder";
import ConfirmedOrder from "./views/base/OrderControl/ConfirmedOrder/ConfirmedOrder";
import RefundOrder from "./views/base/OrderControl/RefundOrder/RefundOrder";
import ReturnOrder from "./views/base/OrderControl/ReturnOrder/ReturnOrder";
import Delivered from "./views/base/OrderControl/DeleviredOrder/Delivered";
import AllOrder from "./views/base/OrderControl/AllOrder/AllOrder";
import CancelOrder from "./views/base/OrderControl/CancelOrder/CancelOrder";
import Support from "./views/base/Support/Support";
import GetAllRating from "./views/base/Rating/GetAllRating";
import GSTReport from "./views/base/GSTReport/GSTReport";

const Breadcrumbs = React.lazy(() =>
  import("./views/base/breadcrumbs/Breadcrumbs")
);
// ====================productpage
const Rings = React.lazy(() => import("./views/base/Prodcutpage/Rings"));
const NosePin = React.lazy(() => import("./views/base/Prodcutpage/NosePin"));
const NoseRings = React.lazy(() =>
  import("./views/base/Prodcutpage/NoseRings")
);
const Necklaces = React.lazy(() =>
  import("./views/base/Prodcutpage/Necklaces")
);
const Manglasutra = React.lazy(() =>
  import("./views/base/Prodcutpage/Manglasutra")
);
const Pendent = React.lazy(() => import("./views/base/Prodcutpage/Pendent"));
const Earrings = React.lazy(() => import("./views/base/Prodcutpage/Earrings"));
const Bracelet = React.lazy(() => import("./views/base/Prodcutpage/Bracelet"));

// ================
const Cards = React.lazy(() => import("./views/base/cards/Cards"));
const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));
const Banner = React.lazy(() => import("./views/banner/Banner"));
const Colors = React.lazy(() => import("./views/theme/colors/Colors"));
const Typography = React.lazy(() =>
  import("./views/theme/typography/Typography")
);

const CurrencyUpdate = React.lazy(() =>
  import("./views/base/CurrencyUpdate/CurrencyUpdate")
);

const ChangePassword = React.lazy(() =>
  import("./views/base/changepassword/ChangePassword")
);

// ===========================Product
const AllProduct = React.lazy(() =>
  import("./views/base/AllProduct/AllProduct")
);
const ViewChildProduct = React.lazy(() =>
  import("./views/base/AllProduct/ViewChildProduct")
);
const UpdateProduct = React.lazy(() =>
  import("./views/base/AddProduct/UpdateProduct")
);

const UpdateFeatureProduct = React.lazy(() =>
  import("./views/base/FeatureProduct/UpdateFeatureProduct")
);
const FeatureProduct = React.lazy(() =>
  import("./views/base/FeatureProduct/FeatureProduct")
);
// ================================Order======================
const OrderList = React.lazy(() => import("./views/OrderList/OrderList"));
const OrderRefund = React.lazy(() =>
  import("./views/base/OrderRefund/OrderRefund")
);
const OrderReturn = React.lazy(() =>
  import("./views/base/ReturnOrder/OrderReturn")
);

const OrderLatest = React.lazy(() =>
  import("./views/base/orderLatest/OrderLatest")
);
const OrderDetail = React.lazy(() =>
  import("./views/base/OrderDetail/OrderDetail")
);

const OrderTrans = React.lazy(() =>
  import("./views/base/OrderTransist/OrderTrans")
);
const OrderReport = React.lazy(() =>
  import("./views/base/OrderReport/OrderReport")
);
// ==========================Categories=====================================c
const ProductCategories = React.lazy(() =>
  import("./views/base/ProductCategories/ProductCategories")
);
// ===================================NewsLetter===================================
const NewLetterSu = React.lazy(() =>
  import("./views/base/NewsLetterTable/NewLetterSu")
);

// =========================customerList======================================

const CustomersList = React.lazy(() =>
  import("./views/base/CustomersList/CustomersList")
);
const Profile = React.lazy(() => import("./views/pages/Profile/Profile"));

// ================================================Webiste=============

const ProductBanner = React.lazy(() =>
  import("./views/base/ProductBanner/ProductBanner")
);
const ContactUs = React.lazy(() => import("./views/base/ContactUs/ContactUs"));

const UpdateCharges = React.lazy(() =>
  import("./views/base/UpdateCharges/UpdateCharges")
);
const Discount = React.lazy(() =>
  import("./views/base/Offer&Discount/Discount")
);

const DeliveredProduct = React.lazy(() =>
  import("./views/base/DeliverdProduct/DeliveredProduct")
);
const AllOrderDetail = React.lazy(() =>
  import("./views/base/AllOrderDetail/AllOrderDetail")
);
const AllSale = React.lazy(() => import("./views/base/AllSale/AllSale"));
const TexEditior = React.lazy(() =>
  import("./views/base/TextEditior/TexEditior")
);

const WishList = React.lazy(() => import("./views/base/WishList/WishList"));
const FAQ = React.lazy(() => import("./views/base/FaQ/FAQ"));

const HighDemandProduct = React.lazy(() =>
  import("./views/base/highdemandproduct/HighDemandProduct")
);

const CoupanList = React.lazy(() =>
  import("./views/base/Coupan List/CoupanList")
);
const CategoryImage = React.lazy(() =>
  import("./views/base/categoryImage/CategoryImage")
);

const ShopByCategoryImage = React.lazy(() =>
  import("./views/base/ShopByCategoryIamge/ShopByCategoryImage")
);

const routes = [
  // { path: "/admin/dashboard", exact: true, name: "Home" },

  { path: "/admin/profile", name: "", component: Profile },
  // ======================productpage
  { path: "/admin/Rings", name: "Rings", component: Rings },
  { path: "/admin/Pendent", name: "Pendent", component: Pendent },
  { path: "/admin/Manglasutra", name: "Manglasutra", component: Manglasutra },
  { path: "/admin/NosePin", name: "NosePin", component: NosePin },
  { path: "/admin/NoseRings", name: "NoseRings", component: NoseRings },
  { path: "/admin/Bracelet", name: "Bracelet", component: Bracelet },
  { path: "/admin/Earings", name: "Earrings", component: Earrings },
  { path: "/admin/Necklaces", name: "Necklaces", component: Necklaces },
  { path: "/admin/AllSales", name: "All Sales", component: AllSale },

  // ==========================Order path=========================
  // { path: "/admin/orderlist", name: "OrderList", component: OrderList },
  {
    path: "/admin/orderconfirmed",
    name: "Confirmed Order",
    component: ConfirmedOrder,
  },
  {
    path: "/admin/orderTransit",
    name: "Transit Order",
    component: TransitOrder,
  },
  {
    path: "/admin/Orderdelivered",
    name: "Delivered Order",
    component: Delivered,
  },
  { path: "/admin/returndOrder", name: "Return Order", component: ReturnOrder },
  { path: "/admin/refundOrder", name: "Refund Order", component: RefundOrder },
  { path: "/admin/AllOrder", name: "All Order", component: AllOrder },
  { path: "/admin/cancelOrder", name: "Cancel Order", component: CancelOrder },
  { path: "/admin/order", name: "Order", component: OrderDetail },
  { path: "/admin/Report", name: "Order Report", component: OrderReport },
  { path: "/admin/gstreport", name: "GST Report", component: GSTReport },

  { path: "/admin/Update", name: "UpdateCharges", component: UpdateCharges },
  {
    path: "/admin/CurrencyUpdate",
    name: "CurrencyUpdate",
    component: CurrencyUpdate,
  },
  { path: "/admin/OrderDetails", name: "AllOrder", component: AllOrderDetail },

  // ====================product
  { path: "/admin/Product", name: "Product", component: AllProduct },
  { path: "/admin/products/:cat/:subcat?", component: AllProductByCategory },
  {
    path: "/admin/ChildProduct",
    name: "InnerProduct",
    component: ViewChildProduct,
  },
  {
    path: "/admin/UpdateProduct",
    name: "UpdateProduct",
    component: UpdateProduct,
  },
  {
    path: "/admin/UpdateFeatureProduct",
    name: "UpdateFeatureProduct",
    component: UpdateFeatureProduct,
  },
  {
    path: "/admin/FeatureProduct",
    name: "FeatureProduct",
    component: FeatureProduct,
  },
  {
    path: "/admin/CurrencyUpdate",
    name: "CurrencyUpdate",
    component: CurrencyUpdate,
  },
  {
    path: "/admin/HighDemandProduct",
    name: "HighDemandProduct",
    component: HighDemandProduct,
  },

  // ========================Website==================

  { path: "/admin/ContactUs", name: "ContactUs", component: ContactUs },
  {
    path: "/admin/ProductBannerUpload",
    name: "ProductBanner",
    component: ProductBanner,
  },
  { path: "/admin/banner", name: "Banner", component: Banner },
  {
    path: "/admin/shopbyCategory",
    name: "ShopByCategory",
    component: ShopByCategoryImage,
  },
  // {
  //   path: "/admin/TextEditior",
  //   name: "TextEditior",
  //   component: TextEditior,
  // },
  {
    path: "/admin/TextEditior",
    name: "TextEditior",
    component: TexEditior,
  },

  {
    path: "/admin/support",
    name: "Support",
    component: Support,
  },

  {
    path: "/admin/CategoryImage",
    name: "Category Image",
    component: CategoryImage,
  },

  //Rating
  {
    path: "/admin/rating",
    name: "All Rating & Review",
    component: GetAllRating,
  },

  // =======================Marketing

  { path: "/admin/CoupanList", name: "CoupanList", component: CoupanList },
  { path: "/admin/Discount", name: "Discount", component: Discount },
  {
    path: "/admin/ProductBasedCoupon",
    name: "Discount",
    component: ListProductBasedCoupon,
  },
  {
    path: "/admin/Useralldiscount",
    name: "Discount",
    component: UserAllDiscount,
  },
  {
    path: "/admin/Useralldiamonddiscount",
    name: "Discount",
    component: ListDiamondTypeUserCoupon,
  },
  {
    path: "/admin/General/Discount",
    name: "Discount",
    component: ListCategoryBasedGeneralCoupon,
  },
  {
    path: "/admin/General/ProductBasedCoupon",
    name: "Discount",
    component: ListProductBasedGeneralCoupon,
  },
  {
    path: "/admin/General/Useralldiscount",
    name: "Discount",
    component: ListAllGeneralCoupon,
  },
  {
    path: "/admin/General/Useralldiamonddiscount",
    name: "Discount Diamond",
    component: ListDiamondCoupon,
  },

  {
    path: "/admin/DeilveredProduct",
    name: "Deliverd Product",
    component: DeliveredProduct,
  },

  // =========================Catgoris Path==================
  { path: "/admin/Category", name: "Categories", component: ProductCategories },

  // =================================NewsLetter Path===========
  { path: "/admin/Newsletter", name: "Newsletter", component: NewLetterSu },
  { path: "/admin/FAQ", name: "FAQ", component: FAQ },

  // =========================customers===========================
  {
    path: "/admin/CustomerList",
    name: "CustomerList",
    component: CustomersList,
  },
  // ====================Wishlist======================
  {
    path: "/admin/WishList",
    name: "WishList",
    component: WishList,
  },

  { path: "/admin/dashboard", name: "Dashboard", component: Dashboard },

  { path: "/performance", name: "Performance", component: Performance },

  {
    path: "/base/changepassword",
    name: "ChangePassword",
    component: ChangePassword,
  },
  { path: "/theme", name: "Theme", component: Colors, exact: true },
  { path: "/theme/typography", name: "Typography", component: Typography },
  { path: "/base", name: "Base", component: Cards, exact: true },
  { path: "/base/breadcrumbs", name: "Breadcrumbs", component: Breadcrumbs },
];

export default routes;
