import React from 'react'
import AllOrder from '../AllOrder/AllOrder'

const CancelOrder = () => {
  return (
  
    <AllOrder status= 'CANCEL' />
  )
}

export default CancelOrder