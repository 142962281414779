import React from 'react'
import AllOrder from '../AllOrder/AllOrder'

const ReturnOrder = () => {
  return (
   
    <AllOrder status = 'RETURN'  />
  )
}

export default ReturnOrder