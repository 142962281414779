import React from 'react'
import AllOrder from '../AllOrder/AllOrder'

const TransitOrder = () => {
  return (
    
    <AllOrder status = 'TRANSIT' />
  )
}

export default TransitOrder