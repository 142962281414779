import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import API from "../http-common";

export const getProfile = createAsyncThunk(
  "login/getProfile",
  async (thunkAPI) => {
    try {
      let res = await API.get("admin/adminProfile");
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const userlogout = createAsyncThunk("login/logout", async (thunkAPI) => {
  try {
    let res = await API.get("admin/logout");
    return res.data;
  } catch (error) {
    thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateadminProfile = createAsyncThunk(
  "login/updateProfile",
  async (thunkAPI) => {
    try {
      let res = await API.putFormData("admin/profileUpdate", thunkAPI);
      console.log(res);
      alert(res?.data?.message);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const dasboardGrafUser = createAsyncThunk(
  "login/dasboardGrafUser",
  async (thunkAPI) => {
    try {
      const res = await API.get(`admin/dasboardGrafUser`);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const dasboardGrafProduct = createAsyncThunk(
  "login/dasboardGrafProduct",
  async (thunkAPI) => {
    try {
      const res = await API.get(`admin/dasboardGrafProduct`);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const dasboardGrafTotalOrder = createAsyncThunk(
  "login/dasboardGrafTotalOrder",
  async (thunkAPI) => {
    try {
      const res = await API.get(`admin/dasboardGrafTotalOrder`);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GrafTotalDeliveredOrder = createAsyncThunk(
  "login/GrafTotalDeliveredOrder",
  async (thunkAPI) => {
    try {
      const res = await API.get(`admin/GrafTotalDeliveredOrder`);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GrafTotalSales = createAsyncThunk(
  "login/GrafTotalSales",
  async (thunkAPI) => {
    try {
      const res = await API.get(`admin/GrafTotalSales`);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GrafTotalTransitOrder = createAsyncThunk(
  "login/GrafTotalTransitOrder",
  async (thunkAPI) => {
    try {
      const res = await API.get(`admin/GrafTotalTransitOrder`);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const FetchNavbar = createAsyncThunk(
  "login/FetcNavbar",
  async (thunkAPI) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/showProduct/getcategoryData`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Token: ` ${thunkAPI}`,
          },
        }
      );

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const addhomeSliderApi = createAsyncThunk(
  "login/addhomeslider",
  async (initialPost, thunkAPI) => {
    try {
      const res = await API.postFormData(`admin/addhomeSlider/`, initialPost);
      // console.log(res.data);

      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const gethomeSliderApi = createAsyncThunk(
  "gethomeslider",
  async (initialState, thunk) => {
    const response = await API.get(`admin/getAllhomeSlider`);

    console.log(response);
    return response.data.banner;
  }
);

export const updatehomeSliderApi = createAsyncThunk(
  "gupdatehomeslider",
  async (initialState, thunk) => {
    const response = await API.postFormData(
      `admin/updatehomeSlider`,
      initialState
    );

    console.log(response);
    return response.data.banner;
  }
);
export const removehomeSliderApi = createAsyncThunk(
  "removehomeslider",
  async (initialState, thunk) => {
    const response = await API.post(`admin/deletehomeSlider `, initialState);

    console.log(response);
    return response.data.banner;
  }
);

export const fetchproductbycat = createAsyncThunk(
  "productbycat",
  async (initialState, thunk) => {
    try {
      let subcat = encodeURIComponent(initialState.subcat);
      const response = await API.get(
        `admin/productbycategory?cat=${initialState.link}&subcat=${subcat}&count=${initialState.page}&limits=${initialState.limits}`
      );
      console.log(response, "response");
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const searchProduct = createAsyncThunk(
  "searchProduct",
  async (initialState, thunk) => {
    try {
      let subcat = encodeURIComponent(initialState.subcat);
      const response = await API.get(
        `admin/productSearch?cat=${initialState.link}&subcat=${subcat}&searchText=${initialState.searchkey}`
      );

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const fetchsingleproduct = createAsyncThunk(
  "fetchsingleproduct",
  async (initialState, thunk) => {
    const { parentSku, color } = initialState;
    let url = `admin/getproductbysku?parentSku=${parentSku}`;
    if (color) {
      url += `&color=${color}`;
    }
    const response = await API.get(url);

    return response.data.data[0];
  }
);

export const updatesingleproduct = createAsyncThunk(
  "updatesingleproduct",
  async (initialState, thunk) => {
    const { parentSku, color, body } = initialState;
    let url = `admin/updateProduct?parentSku=${parentSku}`;
    if (color) {
      url += `&color=${color}`;
    }

    try {
      const response = await API.put(url, body);

      console.log(response, "response");
      alert("Update Successful");

      return response.data;
    } catch (error) {
      console.log(error, "error");
      alert("Error updating product");
    }
  }
);

export const Fetchdata = createAsyncThunk(
  "login/Fetchdata",
  async (initialPost, thunkAPI) => {
    try {
      const res = await API.post(`admin/login`, initialPost);

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetNewsLetterData = createAsyncThunk(
  "login/GetNewsLetterData",
  async (thunkAPI) => {
    try {
      const res = await API.get(`admin/getAllUserSubscription`);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetUserData = createAsyncThunk(
  "login/GetUserData",
  async (thunkAPI) => {
    try {
      const res = await API.get(`admin/user/userList`);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetAllProduct = createAsyncThunk(
  "login/GetAllProduct",
  async (page, thunkAPI) => {
    // console.log(thunkAPI);

    try {
      const res = await API.get(`admin/allproduct/${page}`);

      // console.log(res.data,"Get Product");
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
      // console.log(error,"getproductError")
    }
  }
);

// ================== Get Featured Product =================

export const GetFeatureProduct = createAsyncThunk(
  "login/FeatureProduct",
  async (thunkAPI) => {
    try {
      const res = await API.get(`admin/getfeatureProduct/`);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const ContectFormdata = createAsyncThunk(
  "login/ContectFormdata",
  async (thunkAPI) => {
    try {
      const res = await API.get(`admin/contact/getAllContacts`);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// ====================================Gold API===================
// 1-getAlldata
// 2-Add Data
// 3-Update data
// 4-delete data
export const GoldPrice = createAsyncThunk(
  "login/GoldPrice",
  async (thunkAPI) => {
    try {
      const res = await API.get(`admin/gold-price/getAllGoldPrice`);
      console.log(res);

      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const AddGoldPrice = createAsyncThunk(
  "login/AddGoldPrice",
  async (initialPost, thunkAPI) => {
    try {
      const res = await API.post(`admin/gold-price/addGoldPrice`, initialPost);
      // console.log(res.data, "res");
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const UpdateGoldPrice = createAsyncThunk(
  "login/UpdateGoldPrice",
  async (initialPost, thunkAPI) => {
    try {
      const res = await API.post(
        `admin/gold-price/updateGoldPrice`,
        initialPost
      );
      // console.log(res.data);

      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const deleteGoldPrice = createAsyncThunk(
  "login/deleteGoldPrice",
  async (initialPost, thunkAPI) => {
    try {
      const res = await API.post(
        `admin/gold-price/deleteGoldPrice`,
        initialPost
      );

      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// ==========================================Gold API ENd

// gemStone api start

export const getGems = createAsyncThunk("login/getGems", async (thunkAPI) => {
  try {
    const res = await API.get(`admin/gemStone-price/getAllGemStonePrice`);
    console.log(res, "for gems");

    return res.data;
  } catch (error) {
    thunkAPI.rejectWithValue(error.response.data);
  }
});

// http://localhost:5000/admin/gemStone-price/addGemStonePrice

export const addGemsPrice = createAsyncThunk(
  "login/addGemsPrice",
  async (initialPost, thunkAPI) => {
    try {
      const res = await API.post(
        `admin/gemStone-price/addGemStonePrice`,
        initialPost
      );
      console.log(res.data, "res for adding gems");
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateGemsPrice = createAsyncThunk(
  "login/updateGemsPrice",
  async (initalpost, thunkAPI) => {
    try {
      const res = await API.post(
        "admin/gemStone-price/updateGemStonePrice",
        initalpost
      );
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteGemsPrice = createAsyncThunk(
  "login/deleteGemsPrice",
  async (initalpost, thunkAPI) => {
    try {
      const res = await API.post(
        "admin/gemStone-price/deleteGemStonePrice",
        initalpost
      );
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// gemStone api end

// ================================================Diamond API Start
// 1-getAlldata
// 2-Add Data
// 3-Update data
// 4-delete data
export const DiamondPrice = createAsyncThunk(
  "login/DiamondPrice",
  async (thunkAPI) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: ` ${userTokenn}`,
        },
      };
      const res = await API.get(
        `admin/diamond-price/getAllDiamondPrice`,
        config
      );

      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const AddDiamondPrice = createAsyncThunk(
  "login/AddDiamondPrice",
  async (initialPost, thunkAPI) => {
    try {
      const res = await API.post(
        `admin/diamond-price/addDiamondPrice`,
        initialPost
      );

      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const UpdateDiamondPrice = createAsyncThunk(
  "login/UpdateDiamondPrice",
  async (initialPost, thunkAPI) => {
    console.log();
    try {
      const res = await API.post(
        `admin/diamond-price/updateDiamondPrice`,
        initialPost
      );

      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteDiamondPrice = createAsyncThunk(
  "login/deleteDiamondPrice",
  async (initialPost, thunkAPI) => {
    try {
      const res = await API.post(
        `admin/diamond-price/deleteDiamondPrice`,
        initialPost
      );
      // console.log(res);

      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// ====================================Diamond Api End============================

// ==========================================FAQ=========================

export const FAQ = createAsyncThunk("login/FAQ", async (thunkAPI) => {
  try {
    const res = await API.get(`admin/faq/getAllFaq`);
    return res.data;
  } catch (error) {
    thunkAPI.rejectWithValue(error.response.data);
  }
});
export const ADDFAQ = createAsyncThunk(
  "login/ADDFAQ",
  async (initialPost, thunkAPI) => {
    try {
      const res = await API.post(`admin/faq/addFaq`, initialPost);

      // console.log(initialPost,"initalpost")
      return res.data;
    } catch (error) {
      // thunkAPI.rejectWithValue(error.response.data);

      console.log(error);
    }
  }
);
export const UPDATEFAQ = createAsyncThunk(
  "login/UPDATEFAQ",
  async (initialPost, thunkAPI) => {
    try {
      const res = await API.post(`admin/faq/updateFaq`, initialPost);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const DELETEFAQ = createAsyncThunk(
  "login/DELETEFAQ",
  async (initialPost, thunkAPI) => {
    // console.log(initialPost, "initialPost");
    try {
      const res = await API.post(`admin/faq/deleteFaq`, initialPost);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// =============================Faq end================

// ======================coupan start

export const AddCoupan = createAsyncThunk(
  "login/AddCoupan",
  async (initialPost, thunkAPI) => {
    // console.log(initialPost, "initialPost");

    try {
      const res = await API.post(
        `admin/DisCount/addGeneralDiscount`,
        initialPost
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      alert(error.response.data.message);
    }
  }
);

export const GetCoupan = createAsyncThunk(
  "login/GetCoupan",
  async (thunkAPI) => {
    try {
      const res = await API.get(`admin/DisCount/getAllGeneralDiscount`);
      console.log(res.data);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const DeleteCoupan = createAsyncThunk(
  "login/DeleteCoupan",
  async (initialPost, thunkAPI) => {
    // console.log(initialPost, "initialPost");

    try {
      const res = await API.post(
        `admin/DisCount/GeneralDiscountDelete`,
        initialPost
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const UpdateCoupan = createAsyncThunk(
  "login/UpdateCoupan",
  async (initialPost, thunkAPI) => {
    // console.log(initialPost, "initialPost");

    try {
      const res = await API.post(
        `admin/DisCount/UpdateGeneralDiscount`,
        initialPost
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// =============================Coupan end================

// ===============================Product Category==============
export const GetProductCategory = createAsyncThunk(
  "login/GetProductCategory",
  async (thunkAPI) => {
    try {
      const res = await API.get(`admin/showProduct/getallcategoryData`);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const AddProductCategory = createAsyncThunk(
  "login/AddProductCategory",
  async (initialPost, thunkAPI) => {
    // console.log(initialPost, "initialPost");

    try {
      const res = await API.post(`admin/showProduct/addCategory`, initialPost);
      // console.log(res);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const DeleteCategory = createAsyncThunk(
  "login/DeleteCategory",
  async (id, thunkAPI) => {
    // console.log(id, "initialPost");

    try {
      const res = await API.deleteData(
        `admin/showProduct/CategoryDeletebyId/${id}`
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const UpdateProductCategory = createAsyncThunk(
  "login/UpdateProductCategory",
  async (initialPost, thunkAPI) => {
    // console.log(initialPost, "initialPost");

    try {
      const res = await API.post(
        `admin/showProduct/updateCategory`,
        initialPost
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const BlockProductCategory = createAsyncThunk(
  "login/BlockProductCategory",
  async (initialPost, thunkAPI) => {
    // console.log(initialPost, "initialPost");

    try {
      const res = await API.post(
        `admin/Product/productStatusbycategory`,
        initialPost
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// ===============================Product End==============

// ===================Delete Product =============================

export const DeleteProduct = createAsyncThunk(
  "login/DeleteProduct",
  async (id, thunkAPI) => {
    console.log(id, "initialPost");

    try {
      const res = await API.deleteData(
        `admin/product/deleteProductByParentSKU/${id}`
      );
      console.log(res);
      // return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const DeleteProductAllProductByCat = createAsyncThunk(
  "login/DeleteProductAllProductByCat",
  async (data, thunkAPI) => {
    try {
      const res = await API.put(`admin/deletedata`, data);
      alert(res?.data?.message);
      // return res.data;
    } catch (error) {
      alert(error?.data?.response?.message || "Something went wrong");
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

////////////////////////Get Child Products ////////////////

export const GetChildProduct = createAsyncThunk(
  "login/GetChildProduct",
  async (data, thunkAPI) => {
    try {
      const res = await API.get(
        `admin/product/getProductByParentSKU?parentSku=${data.skuId}&page=${data.page}`
      );

      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// ================Making Charges API =============================

export const GetMakeCharge = createAsyncThunk(
  "login/GetMakeCharge",
  async (thunkAPI) => {
    try {
      const res = await API.get(`admin/GstMakecharge/getMakeCharge`);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const UpdateMakePrice = createAsyncThunk(
  "login/UpdateMakePrice",
  async (initialPost, thunkAPI) => {
    try {
      const res = await API.post(
        `admin/GstMakecharge/updateMakeCharge`,
        initialPost
      );
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// ===========================Text Editiot============

export const GetTextEditiorContent = createAsyncThunk(
  "login/GetTextEditiorContent",
  async (id, thunkAPI) => {
    try {
      const res = await API.get(
        `admin/PrivacyPolicy/getPrivacyPolicybyId/646c3ca5d9eadd1540aa732a`
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GetAboutTextEditiorContent = createAsyncThunk(
  "login/GetAboutTextEditiorContent",
  async (thunkAPI) => {
    // console.log(id, "initialPost");

    try {
      const res = await API.get(
        `admin/About/getAboutUsbyId/6465c9ebe1027614bc30f3e2`
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GetTermTextEditiorContent = createAsyncThunk(
  "login/GetTermTextEditiorContent",
  async (thunkAPI) => {
    // console.log(id, "initialPost");

    try {
      const res = await API.get(
        `admin/TermCondition/getTermAndConditionsbyId/6467195459d2d81934bd4b4b`
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const UpdatePrivacyPolicy = createAsyncThunk(
  "login/UpdatePrivacyPolicy",
  async (initialPost, thunkAPI) => {
    // console.log(initialPost, "initialPost");

    try {
      const res = await API.post(
        `admin/PrivacyPolicy/updatePrivacyPolicy`,
        initialPost
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const UpdateTermsCondition = createAsyncThunk(
  "login/UpdateTermsCondition",
  async (initialPost, thunkAPI) => {
    // console.log(initialPost, "initialPost");

    try {
      const res = await API.post(
        `admin/TermCondition/updateTermAndConditions`,
        initialPost
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const UpdateAbout = createAsyncThunk(
  "login/UpdateAbout",
  async (initialPost, thunkAPI) => {
    // console.log(initialPost, "initialPost");

    try {
      const res = await API.post(`admin/About/updateAboutUs`, initialPost);
      // console.log(res);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// ===========================GST======================

export const GstCharge = createAsyncThunk(
  "login/GstCharge",
  async (thunkAPI) => {
    // console.log(id, "initialPost");

    try {
      const res = await API.get(`admin/GstMakecharge/getGst`);
      // console.log(res);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const UpdateGstCharge = createAsyncThunk(
  "login/UpdateGstCharge",
  async (initialPost, thunkAPI) => {
    // console.log(id, "initialPost");

    try {
      const res = await API.post(`admin/GstMakecharge/updateGst`, initialPost);
      // console.log(res.data);/
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// =================================Wishlist==============================

export const Wishlist = createAsyncThunk("login/Wishlist", async (thunkAPI) => {
  // console.log(id, "initialPost");

  try {
    const res = await API.get(`admin/wishlist/getWishlist`);
    return res.data;
  } catch (error) {
    thunkAPI.rejectWithValue(error.response.data);
  }
});

// ============================UserDiscount==================
export const UserDiscount = createAsyncThunk(
  "login/UserDiscount",
  async (initialPost, thunkAPI) => {
    // console.log(initialPost, "initialPost");

    try {
      const res = await API.post(`admin/addUserDiscount`, initialPost);
      // console.log(res.dat/a);
      alert(res?.data?.message);
      return res?.data;
    } catch (error) {
      alert(error.response.data.message);
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetAllUserDiscount = createAsyncThunk(
  "login/GetAllUserDiscount",
  async (thunkAPI) => {
    // console.log(id, "initialPost");

    try {
      const res = await API.get(
        `admin/DisCount/getAllUserDiscount?type=${thunkAPI.type}`
      );
      // console.log(res);

      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const UpdateUserDiscount = createAsyncThunk(
  "login/UpdateUserDiscount",
  async (initialPost, thunkAPI) => {
    // console.log(initialPost, "initialPost");

    try {
      const res = await API.post(
        `admin/DisCount/UpdateUserDiscount`,
        initialPost
      );
      // console.log(res.data);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const UpdateUserDiscountStatus = createAsyncThunk(
  "login/UpdateStatusUserDiscount",
  async (initialPost, thunkAPI) => {
    // console.log(initialPost, "initialPost");

    try {
      const res = await API.post(`admin/UpdateUserDiscountstatus`, initialPost);
      // console.log(res.data);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const DeleteUserDiscount = createAsyncThunk(
  "login/DeleteUserDiscount",
  async (initialPost, thunkAPI) => {
    // console.log(initialPost, "initialPost");

    try {
      const res = await API.post(`admin/userDiscountDelete`, initialPost);
      // console.log(res.data);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

/// General coupon /////

export const GeneralDiscount = createAsyncThunk(
  "login/UserDiscount",
  async (initialPost, thunkAPI) => {
    // console.log(initialPost, "initialPost");

    try {
      const res = await API.post(
        `admin/DisCount/addGeneralDiscount`,
        initialPost
      );
      // console.log(res.dat/a);
      alert(res.data.message);
      return res.data;
    } catch (error) {
      alert(error.response.data.message);
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetAllGeneralDiscount = createAsyncThunk(
  "login/GetAllGeneralDiscount",
  async (thunkAPI) => {
    // console.log(id, "initialPost");

    try {
      const res = await API.get(
        `admin/DisCount/getAllGeneralDiscount?type=${thunkAPI.type}`
      );
      // console.log(res);

      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const UpdateGeneralDiscount = createAsyncThunk(
  "login/UpdateGeneralDiscount",
  async (initialPost, thunkAPI) => {
    // console.log(initialPost, "initialPost");

    try {
      const res = await API.post(
        `admin/DisCount/UpdateUserDiscount`,
        initialPost
      );
      // console.log(res.data);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const UpdateGeneralDiscountStatus = createAsyncThunk(
  "login/UpdateGeneralDiscountStatus",
  async (initialPost, thunkAPI) => {
    // console.log(initialPost, "initialPost");

    try {
      const res = await API.post(
        `admin/DisCount/UpdateGeneralDiscountstatus`,
        initialPost
      );
      // console.log(res.data);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const DeleteGeneralDiscount = createAsyncThunk(
  "login/DeleteGeneralDiscount",
  async (initialPost, thunkAPI) => {
    // console.log(initialPost, "initialPost");

    try {
      const res = await API.post(
        `admin//DisCount/GeneralDiscountDelete`,
        initialPost
      );
      // console.log(res.data);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetAllUserOrder = createAsyncThunk(
  "login/GetAllUserOrder",
  async (thunkAPI) => {
    // console.log(id, "initialPost");

    try {
      const res = await API.get(`user/getOredr`);
      // console.log(res);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const AddCurrency = createAsyncThunk(
  "login/AddCurrency",
  async (initialPost, thunkAPI) => {
    console.log(initialPost, "initialPost");

    try {
      const res = await API.post(`admin/addCurrency`, initialPost);
      // console.log(res.data);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const CurrencyDelete = createAsyncThunk(
  "login/CurrencyDelete",
  async (initialPost, thunkAPI) => {
    console.log(initialPost, "initialPost");

    try {
      const res = await API.post(`admin/CurrencyDelete`, initialPost);
      // console.log(res.data);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const CurrencyUpdatee = createAsyncThunk(
  "login/CurrencyUpdatee",
  async (initialPost, thunkAPI) => {
    console.log(initialPost, "initialPost");

    try {
      const res = await API.post(`admin/UpdateCurrency`, initialPost);
      // console.log(res.data);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getCurrencydata = createAsyncThunk(
  "login/getCurrencydata",
  async (thunkAPI) => {
    try {
      const res = await API.get(`admin/getAllCurrency`);
      // console.log(res);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// ====================Get Categories data for banner =================================

export const GetProductCategoryBanner = createAsyncThunk(
  "login/admin/ProductBannerUpload",
  async (thunkAPI) => {
    try {
      const res = await API.get(`admin/getcatsubcatData`);
      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// ============================ ADD BANNER =============================

export const AddBanner = createAsyncThunk(
  "AddBannerlogin/admin/ProductBannerUpload",
  async (initialPost, thunkAPI) => {
    try {
      const res = await API.postFormData(`admin/addBanner`, initialPost);
      debugger;
      alert(res.data.message);

      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
      alert(error.response.data.message);
    }
  }
);
export const GetBanner = createAsyncThunk(
  "GetBannerlogin/admin/ProductBannerUplo",
  async (thunkAPI) => {
    try {
      const res = await API.get(`admin/getAllBanner`);

      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const DeleteBanner = createAsyncThunk(
  "DeleteBanner/admin/ProductBannerUplo",
  async (initialPost, thunkAPI) => {
    try {
      const res = await API.post(`admin/deleteBanner`, initialPost);

      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const UpdateBanner = createAsyncThunk(
  "UpdateBannerlogin/admin/UpdateBanner",
  async (initialPost, thunkAPI) => {
    try {
      const res = await API.postFormData(`admin/updateBanner`, initialPost);
      alert(res.data.message);

      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
      alert(error.response.data.message);
    }
  }
);

//  category and subCategory images upload starts here

export const addCatSubCatImg = createAsyncThunk(
  "login/addCatSubCatImg",
  async (initialPost, thunkAPI) => {
    // console.log(initialPost,'initialPost');
    try {
      const res = await API.postFormData(
        "admin/addSubCategoryBanner",
        initialPost
      );
      alert(res.data.message);
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getCatSubCatImg = createAsyncThunk(
  "login/getCatSubCatImg",
  async (initialPost, thunkAPI) => {
    // console.log(initialPost,'initialPost');
    try {
      const res = await API.get("admin/getAllSubCategoryBanner", initialPost);
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateCatSubCatImg = createAsyncThunk(
  "login/updateCatSubCatImg",
  async (initialPost, thunkAPI) => {
    // console.log(initialPost,'initialPost');
    try {
      const res = await API.postFormData(
        "admin/updateSubCategoryBanner",
        initialPost
      );
      alert(res.data.message);
      return res.data;
    } catch (err) {
      console.log(err);
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteCatSubCatImg = createAsyncThunk(
  "login/getCatSubCatImg",
  async (initialPost, thunkAPI) => {
    // console.log(initialPost,'initialPost');
    try {
      const res = await API.post("admin/deleteSubCategoryBanner", initialPost);
      alert(res.data.message);
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

//  category and subCategory images upload ends here

///Shop by category

export const AddShopByCatImage = createAsyncThunk(
  "AddShopByCatImage/admin/AddShopByCatImage",
  async (initialPost, thunkAPI) => {
    try {
      const res = await API.postFormData(`admin/addModelBanner`, initialPost);
      alert(res.data.message);

      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
      alert(error.response.data.message);
    }
  }
);
export const GetShopByCatImage = createAsyncThunk(
  "GetShopByCat/admin/getShopByCat",
  async (thunkAPI) => {
    try {
      const res = await API.get(`admin/getAllModelBanner`);

      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const DeleteShopByCatImage = createAsyncThunk(
  "Delete/admin/deleteUpdateShopByCat",
  async (initialPost, thunkAPI) => {
    try {
      const res = await API.post(`admin/deleteModelBanner`, initialPost);

      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const UpdateShopByCatImage = createAsyncThunk(
  "UpdateShopByCat/admin/UpdateShopByCat",
  async (initialPost, thunkAPI) => {
    try {
      const res = await API.postFormData(
        `admin/updateModelBanner`,
        initialPost
      );
      alert(res.data.message);

      return res.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error.response.data);
      alert(error.response.data.message);
    }
  }
);

const userTokenn = localStorage.getItem("userToken")
  ? localStorage.getItem("userToken")
  : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiVXN5dWl1Nzg3IiwiZW1haWwiOiJ0aHVuZGVybWFpbDJAaGkyLmluIiwicm9sZSI6ImFkbWluIiwiaWF0IjoxNzA4Njc4OTQ1LCJleHAiOjE3MDk4ODg1NDV9._6Twnz6ipt3pGyxiR2zLmF8a-tKw8wCDHk52gQZlGj4";

console.log(userTokenn);

const AdminSlice = createSlice({
  name: "login",
  initialState: {
    profile: {},
    isLoading: false,
    data: [],
    childData: [],
    featureProduct: [],
    gold: [],
    about: [],
    term: [],
    WishList: [],
    makecharge: [],
    gst: [],
    parentsku: "",
    privacy: [],
    diamond: [],
    catforbanner: [],
    shopByCatImage: [],
    isErorr: false,
    userToken: null,
    isAuthenticated: false,
    banner: [],
    bannerStatus: [],
    gems: [],
    addGems: [],
    updateGems: [],
    deleteGems: [],
    navbarData: [],
    addCategorySubCategoryData: [],
    CategorySubCategoryData: [],
    ProductbyCat: [],
    homeSlider: [],
    SingleproductbyCat: {},
    generaldiscount: [],
  },
  extraReducers: (builder) => {
    builder.addCase(Fetchdata.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
    });

    builder.addCase(fetchproductbycat.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.ProductbyCat = action.payload;
    });
    builder.addCase(fetchproductbycat.pending, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.ProductbyCat = [];
    });
    builder.addCase(fetchproductbycat.rejected, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.ProductbyCat = [];
    });

    builder.addCase(searchProduct.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      //  state.ProductbyCat = action.payload;
    });

    builder.addCase(fetchsingleproduct.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.SingleproductbyCat = action.payload;
    });

    builder.addCase(FetchNavbar.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.navbarData = action.payload;
    });

    builder.addCase(gethomeSliderApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.homeSlider = action.payload;
    });

    builder.addCase(GetShopByCatImage.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.shopByCatImage = action.payload;
    });

    builder.addCase(GetBanner.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.banner = action.payload;
    });

    builder.addCase(AddBanner.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.bannerStatus = action.payload;
    });

    builder.addCase(GetProductCategoryBanner.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.catforbanner = action.payload;
    });
    // for login
    builder.addCase(Fetchdata.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.data = action.payload;
      state.userToken = userTokenn;
    });

    // for All subscription user
    builder.addCase(GetNewsLetterData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.data = action.payload;
    });
    // for ALl userdata
    builder.addCase(GetUserData.fulfilled, (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.isAuthenticated = true;
      state.data = action.payload;
    });
    // for All product
    builder.addCase(GetAllProduct.fulfilled, (state, action) => {
      // console.log(action,"Action");
      state.isLoading = false;
      state.isAuthenticated = true;
      state.data = action.payload;
    });
    // for contactForms data
    builder.addCase(ContectFormdata.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.data = action.payload;
    });
    // for get Gold
    builder.addCase(GoldPrice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.gold = action.payload;
    });
    // for Add Gold
    builder.addCase(AddGoldPrice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.gold = action.payload;
    });
    // for Update Gold
    builder.addCase(UpdateGoldPrice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.gold = action.payload;
    });
    // for Delete Gold
    builder.addCase(deleteGoldPrice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.gold = action.payload;
    });

    // for get diamond
    builder.addCase(DiamondPrice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.diamond = action.payload;
    });
    // for Add Diamond
    builder.addCase(AddDiamondPrice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.diamond = action.payload;
    });
    // for Diamond Update
    builder.addCase(UpdateDiamondPrice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.diamond = action.payload;
    });

    // for diamond Delete
    builder.addCase(deleteDiamondPrice.fulfilled, (state, action) => {
      // console.log(state);

      state.isLoading = false;
      state.isAuthenticated = true;
      state.diamond = action.payload;
    });
    // Product Category
    builder.addCase(GetProductCategory.fulfilled, (state, action) => {
      // console.log(action);

      state.isLoading = false;
      state.isAuthenticated = true;
      state.userToken = userTokenn;
      state.data = action.payload;
    });

    builder.addCase(FAQ.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.data = action.payload;
    });

    builder.addCase(ADDFAQ.fulfilled, (state, action) => {
      // console.log(action);

      state.isLoading = false;
      state.isAuthenticated = true;
      state.data = action.payload;
    });
    builder.addCase(UPDATEFAQ.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.data = action.payload;
    });
    builder.addCase(DELETEFAQ.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.data = action.payload;
    });
    builder.addCase(AddCoupan.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.data = action.payload;
    });
    builder.addCase(GetCoupan.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.data = action.payload;
    });
    builder.addCase(DeleteCoupan.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.data = action.payload;
    });
    builder.addCase(UpdateCoupan.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.data = action.payload;
    });

    builder.addCase(GetTextEditiorContent.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.privacy = action.payload;
    });
    builder.addCase(GetAboutTextEditiorContent.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.about = action.payload;
    });

    builder.addCase(GetTermTextEditiorContent.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.term = action.payload;
    });
    builder.addCase(UpdatePrivacyPolicy.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.term = action.payload;
    });
    builder.addCase(UpdateTermsCondition.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.term = action.payload;
    });
    builder.addCase(UpdateAbout.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.term = action.payload;
    });
    builder.addCase(GstCharge.fulfilled, (state, action) => {
      // console.log(action.payload)
      state.isLoading = false;
      state.isAuthenticated = true;
      state.gst = action.payload;
    });
    builder.addCase(UpdateGstCharge.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.gst = action.payload;
    });

    builder.addCase(GetMakeCharge.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.makecharge = action.payload;
    });

    // ================Wishlist====
    builder.addCase(Wishlist.fulfilled, (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      state.isAuthenticated = true;
      state.WishList = action.payload;
    });
    // =======discount
    builder.addCase(UserDiscount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      // state.data = action.payload;
    });

    builder.addCase(GetAllUserDiscount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.data = action.payload;
    });
    builder.addCase(UpdateUserDiscount.fulfilled, (state, action) => {
      // console.log(action.payload)
      state.isLoading = false;
      state.isAuthenticated = true;
      // state.data = action.payload;
    });
    builder.addCase(DeleteUserDiscount.fulfilled, (state, action) => {
      // console.log(action.payload)
      state.isLoading = false;
      state.isAuthenticated = true;
      // state.data = action.payload;
    });
    builder.addCase(GetAllUserOrder.fulfilled, (state, action) => {
      // console.log(action.payload)
      state.isLoading = false;
      state.isAuthenticated = true;
      state.data = action.payload;
    });

    /////////////////Child Data of Product
    builder.addCase(GetChildProduct.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.childData = action.payload;
    });

    // Feature Product
    builder.addCase(GetFeatureProduct.fulfilled, (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.isAuthenticated = true;
      state.featureProduct = action.payload;
    });

    builder.addCase(AddCurrency.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.data = action.payload;
    });
    builder.addCase(getCurrencydata.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.data = action.payload;
    });
    builder.addCase(CurrencyDelete.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.data = action.payload;
    });
    builder.addCase(CurrencyUpdatee.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.data = action.payload;
    });
    builder.addCase(getGems.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.gems = action.payload;
    });

    builder.addCase(addGemsPrice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.addGems = action.payload;
    });

    builder.addCase(updateGemsPrice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.updateGems = action.payload;
    });

    builder.addCase(deleteGemsPrice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.deleteGems = action.payload;
    });
    builder.addCase(Fetchdata.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isErorr = true;
    });

    //Category Image Upload
    builder.addCase(addCatSubCatImg.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.addCategorySubCategoryData = action.payload;
    });
    builder.addCase(getCatSubCatImg.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.CategorySubCategoryData = action.payload;
    });

    builder.addCase(GetAllGeneralDiscount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.data = action.payload;
    });
  },
});

export default AdminSlice.reducer;
