import {
    CCard,
    CCol,
    CCardHeader,
    CRow,
    CCardBody,
    CDataTable,
    CButton,
    CLabel,
    CInput,
  } from "@coreui/react";
  import React from "react";
  import { useEffect } from "react";
  import { useDispatch, useSelector } from "react-redux";

  import { AiFillDelete } from "react-icons/ai";
  import { FiEdit } from "react-icons/fi";
  // import Coupan from "../Coupan/Coupan";
  import { useState } from "react";


  import Dialog from "@mui/material/Dialog";

  import DialogContent from "@mui/material/DialogContent";
  import DialogContentText from "@mui/material/DialogContentText";
  import DialogTitle from "@mui/material/DialogTitle";
  import Slide from "@mui/material/Slide";
import { DeleteGeneralDiscount, GetAllGeneralDiscount, UpdateGeneralDiscount ,UpdateGeneralDiscountStatus } from "src/Redux/UserSlice";
import dayjs from 'dayjs';

import AddCategoryBasedGeneralCoupon from "./AddCategoryBasedGeneralCoupon";

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
  const fields = [
    { key: "SL_NO", label: "SL No", _style: { width: "10%" } },
    {key : "title" , label :"Title", _style :{width:"10%"}},
    { key: "Code", label: "Discount Code", _style: { width: "20%" } },
    { key: "Type", label: "Type", _style: { width: "10%" } },
    { key: "Discount", label: "Discount", _style: { width: "10%" } },
    { key: "uptoValue", label: "Upto Value", _style: { width: "10%" } },
    { key: "Category", label: "Category", _style: { width: "10%" } },
    { key: "subCategory", label: "Sub Category", _style: { width: "10%" } },
    { key: "TandC", label: "Terms and Condition", _style: { minWidth: "300px" } },
   
  
    { key: "startDate", label: "Start Date", _style: { minWidth: "150px" } },
    { key: "endDate", label: "End Date", _style: { minWidth: "150px" } },
  
    { key: "Status", label: "Status", _style: { width: "10%" } },
  
    {
      key: "show_details",
      label: "Action",
      _style: { width: "10%" },
      sorter: false,
      filter: false,
    },
  ];
const ListCategoryBasedGeneralCoupon = () => {
    // ============popup========================
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (item) => {
    seteditData(item)
    setOpen(true);
  };
  // console.log(editdata);

  const handleClose = () => {
    setOpen(false);
  };
  // ===========================================

  const [editdata, seteditData] = useState([]);
  const dispatch = useDispatch();
  const [coupandata, setCoupandata] = useState([]);
  const [success, setSuccess] = useState(false);
  const Data = useSelector((state) => state.user.data?.result);
  console.log(Data);

  useEffect(() => {
    dispatch(GetAllGeneralDiscount({type:'BycatSbcat'}))
  }, []);
  useEffect(() => {
    setCoupandata(Data);
  }, [Data]);

  // console.log(coupandata);

  const HandleChange = (e) => {
    const Newinput = (data) => ({ ...data, [e.target.name]: e.target.value });

    seteditData(Newinput);
  };

  const HandleDelete = (id) => {
    const deleteId = {
      id: id,
    };
     const choice = window.confirm(
      "Are you sure you want to delete Data?"
    )
    // console.log(choice)
    if(choice){
      dispatch(DeleteGeneralDiscount(deleteId))
      setTimeout(() => {
        dispatch(GetAllGeneralDiscount({type:'BycatSbcat'}))
        
      }, 500);

    }
    
   
   
    
    

  };

  const HandleUpdate = (id) => {
    const post = {
      id:editdata._id,
      discountName: editdata.discountName,
      discountType: editdata.discountType,
      discountValue: +editdata.discountValue,
      productcategory: editdata.productcategory,
      expiryDays:+editdata.expiryDays,
    };
  
    dispatch(UpdateGeneralDiscount(post))
    setTimeout(() => {
      dispatch(GetAllGeneralDiscount({type:'BycatSbcat'}))
      
    }, 500);
   

    setOpen(false);
  };


  const statusUpdate = (item)=>{

      let body = {
        status : item.status === 'active'?'inactive':'active',
        id: item._id
      }

      dispatch(UpdateGeneralDiscountStatus(body))
      setTimeout(() => {
        dispatch(GetAllGeneralDiscount({type:'BycatSbcat'}))
        
      }, 500);
  }
  return (
    <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <div className="d-flex justify-content-between">
              <div>Discount List</div>
              <div>
                <CButton color="success" onClick={() => setSuccess(!success)}>
                  Add Discount
                </CButton>
                <AddCategoryBasedGeneralCoupon success={success} setSuccess={setSuccess} Data={Data} />
              </div>
            </div>
          </CCardHeader>

  <CCardBody>
    <CDataTable
      items={coupandata}
      fields={fields}
      tableFilter
      footer ={false}
     
      hover
      sorter
      tableProps={{
        hover: true,
      }}
      scopedSlots={{
        SL_NO: (item, index) => {
          return <td>{index + 1}</td>;
        },
        Code: (item) => {
          return <td>{item?.discountName}</td>;
        },
        Type: (item) => {
          return <td>{item?.DiscountType}</td>;
        },
        Discount: (item) => {
          return <td>{item?.DiscountValue}</td>;
        },
   
        startDate: (item) => {
          return <td>{dayjs(item?.startDate).format('YYYY-MM-DD')}</td>;
        },
        endDate: (item) => {
          return <td>{dayjs(item?.endDate).format('YYYY-MM-DD')}</td>;
        },
        Category: (item) => {
          return <td>{item?.productcategory}</td>;
        },
        subCategory: (item) => {
          return <td>{item?.productSubcat ? item.productSubcat : "N/A" }</td>;
        },
        Status: (item) => {
          return <td>
            <CButton color="success" onClick={() => statusUpdate(item)}>
          {item.status}
        </CButton>
          </td>;
        },
        
        TandC: (item) => {

         
          return (
            <td>
              <ol >
              {item?.TandC?.map((d, index) => (
                  <li key={index}>{d.text}</li>
                  ))}
                  </ol>
            </td>
          );
        },

        show_details: (item, index) => {
          return (
            <td>
              <div className="d-flex gap-3">
                {/* <FiEdit
                  size={18}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleClickOpen(item, index)}
                /> */}
                <AiFillDelete
                  size={18}
                  style={{ cursor: "pointer" }}
                  onClick={() => HandleDelete(item._id)}
                />
              </div>
            </td>
          );
        },
      }}
    />
  </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
  )
}

export default ListCategoryBasedGeneralCoupon