import React, { useState } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CListGroup,
  CListGroupItem,
  CTabContent,
  CTabPane,
  CFade,
  CLabel,
  CInput,
  CCollapse,
  CRow,
  CButton,
} from "@coreui/react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchsingleproduct, updatesingleproduct } from "src/Redux/UserSlice";
import { Button } from "@coreui/coreui";
import API from "src/http-common";
import AddProductBasedCoupon from "../../Coupan/AddProductBasedCoupon";
import ListProductBasedCoupon from "../../Coupan/ListProductBasedCoupon";
import AddProductBasedGerneralCoupon from "../../GeneralCoupon/ProductBasedGereralCoupon/AddProductBasedGeneralCoupon";

const ProductInfoUpdate = () => {
  const dispatch = useDispatch();
  const singleData = useSelector((state) => state?.user?.SingleproductbyCat);

  const [productInfo, setproductInfo] = useState({
    productName: singleData?.productName,
    length: singleData?.length,
    height: singleData?.height,
    width: singleData?.width,
    diamond_ct: singleData?.diamond_ct,
    weight: singleData?.weight,
    // goldType: singleData?.goldType,
    status: singleData?.status,
  });

  const [gemsStone, setgemstone] = useState([]);

  const handleUpdate = () => {
    const choice = window.confirm("Are you sure you want to update Data?");
    let updatedBody = productInfo;
    if (choice) {
      let data = {
        parentSku: singleData.parentSku,
        body: updatedBody,
      };

      if (gemsStone?.length > 0) {
        updatedBody.gemStone = gemsStone;
      }
      // console.log(data);
      dispatch(updatesingleproduct(data));
    }
  };

  useEffect(() => {
    setproductInfo({
      productName: singleData?.productName,
      length: singleData?.length,
      height: singleData?.height,
      width: singleData?.width,
      status: singleData?.status,
      diamond_ct: singleData?.diamond_ct,
      weight: singleData?.weight,
      // goldType: singleData?.goldType,
    });

    setgemstone(singleData?.gemStone || []);
  }, [singleData]);

  const onChangeHandle = (e) => {
    console.log(e.target.value);
    setproductInfo({
      ...productInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handlegemstone = (index, value) => {
    const Allgem = [...gemsStone];
    Allgem[index] = value;
    setgemstone(Allgem);
  };

  return (
    <CRow>
      <CCol xs="12" sm="12" md="6">
        <CLabel className="m-1">Parent SKU</CLabel>
        <CInput
          //   onChange={HandleChange}
          name="discountName"
          disabled
          //   value={data.discountName}
          value={singleData?.parentSku}
        ></CInput>
      </CCol>
      <CCol xs="12" sm="12" md="6">
        <CLabel className="m-1">Product SKU</CLabel>
        <CInput
          //   onChange={HandleChange}
          name="discountName"
          //   value={data.discountName}
          value={singleData?.sku}
          disabled
        ></CInput>
      </CCol>
      <CCol xs="12" sm="12" md="6">
        <CLabel className="m-1">Price</CLabel>
        <CInput
          //   onChange={HandleChange}
          name="discountName"
          //   value={data.discountName}
          value={
            singleData?.PriceArray?.length > 0 &&
            singleData?.PriceArray[0].Final_price
          }
          disabled
        ></CInput>
      </CCol>
      <CCol xs="12" sm="12" md="6">
        <CLabel className="m-1">Name</CLabel>
        <CInput
          //   onChange={HandleChange}
          name="productName"
          //   value={data.discountName}
          value={productInfo?.productName}
          onChange={(e) => onChangeHandle(e)}
        ></CInput>
      </CCol>
      <CCol xs="12" sm="12" md="6">
        <CLabel className="m-1">Diamond_ct</CLabel>
        <CInput
          //   onChange={HandleChange}
          name="diamond_ct"
          //   value={data.discountName}
          value={productInfo?.diamond_ct}
          onChange={(e) => onChangeHandle(e)}
        ></CInput>
      </CCol>
      <CCol xs="12" sm="12" md="6">
        <CLabel className="m-1">Weight</CLabel>
        <CInput
          //   onChange={HandleChange}
          name="weight"
          //   value={data.discountName}
          value={productInfo?.weight}
          onChange={(e) => onChangeHandle(e)}
        ></CInput>
      </CCol>

      <CCol xs="12" sm="12" md="6">
        <CLabel className="m-1">Name</CLabel>
        <CInput
          //   onChange={HandleChange}
          name="productName"
          //   value={data.discountName}
          value={productInfo?.productName}
          onChange={(e) => onChangeHandle(e)}
        ></CInput>
      </CCol>

      <CCol xs="12" sm="12" md="6">
        <CLabel className="m-1 pb-1">Status</CLabel>
        <FormControl
          fullWidth
          variant="outlined"
          size="small"
          // style={{ margin: "0 12px", width: "100%" }}
        >
          <Select
            labelId="demo-simple-select-subCategory"
            id="demo-simple-select-subCategory"
            label="new arrival"
            name="status"
            value={`${productInfo?.status}`}
            onChange={(e) => onChangeHandle(e)}
          >
            <MenuItem value="active">active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
          </Select>
        </FormControl>
      </CCol>

      <CCol xs="12" sm="12" md="6">
        <CLabel className="m-1">Length</CLabel>
        <CInput
          //   onChange={HandleChange}
          name="length"
          //   value={data.discountName}
          type="number"
          value={productInfo?.length}
          onChange={(e) => onChangeHandle(e)}
        ></CInput>
      </CCol>

      <CCol xs="12" sm="12" md="6">
        <CLabel className="m-1">Width</CLabel>
        <CInput
          //   onChange={HandleChange}
          name="width"
          //   value={data.discountName}
          type="number"
          onChange={(e) => onChangeHandle(e)}
          value={productInfo?.width}
        ></CInput>
      </CCol>

      <CCol xs="12" sm="12" md="6">
        <CLabel className="m-1">Height</CLabel>
        <CInput
          //   onChange={HandleChange}
          name="height"
          type="number"
          //   value={data.discountName}
          onChange={(e) => onChangeHandle(e)}
          value={productInfo?.height}
        ></CInput>
      </CCol>

      {gemsStone?.map((item, index) => (
        <CCol xs="12" sm="12" md="6" key={index}>
          <CLabel className="m-1"> GemStone {index + 1} </CLabel>
          <CInput
            //   onChange={HandleChange}
            name="gemstone"
            //   value={data.discountName}
            value={item}
            onChange={(e) => handlegemstone(index, e.target.value)}
          ></CInput>
        </CCol>
      ))}

      <CCol xs="12" sm="12" md="12" className="text-end">
        <CButton color="success " className="mt-3 " onClick={handleUpdate}>
          Update
        </CButton>
      </CCol>
    </CRow>
  );
};

const ImageUpdate = ({ color }) => {
  const dispatch = useDispatch();

  const singleData = useSelector((state) => state?.user?.SingleproductbyCat);

  console.log(singleData, "singledata");

  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");
  const [image5, setImage5] = useState("");
  const [image6, setImage6] = useState("");
  const [image7, setImage7] = useState("");
  const [image8, setImage8] = useState("");

  useEffect(() => {
    setImage1(singleData?.image1 || "");
    setImage2(singleData?.image2 || "");
    setImage3(singleData?.image3 || "");
    setImage4(singleData?.image4 || "");
    setImage5(singleData?.image5 || "");
    setImage6(singleData?.image6 || "");
    setImage7(singleData?.image7 || "");
    setImage8(singleData?.image8 || "");
  }, [singleData]);

  const handleUpdate = () => {
    const choice = window.confirm("Are you sure you want to update Data?");

    if (choice) {
      let body = {};

      //  let body = {
      //     image1 : image1 ? image1 : singleData.image1,
      //     image2 : image2 ? image2 : singleData.image2,
      //     image3 : image3 ? image3 : singleData.image3,
      // }

      body.image1 = image1;

      body.image2 = image2;

      body.image3 = image3;

      body.image4 = image4;

      body.image5 = image5;

      body.image6 = image6;

      body.image7 = image7;

      body.image8 = image8;

      let data = {
        parentSku: singleData.parentSku,
        color: color,
        body: body,
      };

      dispatch(updatesingleproduct(data));
    }
  };

  return (
    <>
      <CRow>
        <CCol xs={6} style={{ padding: "30px 30px" }}>
          <CLabel>Paste Link for Upload Image 1</CLabel>
          <CInput
            name="image1"
            value={image1}
            placeholder="Productimage1"
            aria-label="Productimage1"
            onChange={(e) => setImage1(e.target.value)}
          />
        </CCol>
        {image1 && (
          <CCol xs={6} style={{ padding: "29px 0px" }}>
            <img
              // src={value?.image1}
              src={image1}
              height={200}
              width={200}
              alt="Image"
            />
          </CCol>
        )}
      </CRow>

      <CRow>
        <CCol xs={6} style={{ padding: "30px 30px" }}>
          <CLabel>Paste Link for Upload Image 2</CLabel>
          <CInput
            name="image1"
            value={image2}
            placeholder="Productimage2"
            aria-label="Productimage1"
            onChange={(e) => setImage2(e.target.value)}
          />
        </CCol>
        {image2 && (
          <CCol xs={6} style={{ padding: "29px 0px" }}>
            <img
              // src={value?.image1}
              src={image2}
              height={200}
              width={200}
              alt="Image"
            />
          </CCol>
        )}
      </CRow>

      <CRow>
        <CCol xs={6} style={{ padding: "30px 30px" }}>
          <CLabel>Paste Link for Upload Image 3</CLabel>
          <CInput
            name="image1"
            value={image3}
            placeholder="Productimage3"
            aria-label="Productimage1"
            onChange={(e) => setImage3(e.target.value)}
          />
        </CCol>
        {image3 && (
          <CCol xs={6} style={{ padding: "29px 0px" }}>
            <img
              // src={value?.image1}
              src={image3}
              height={200}
              width={200}
              alt="Image"
            />
          </CCol>
        )}
      </CRow>

      <CRow>
        <CCol xs={6} style={{ padding: "30px 30px" }}>
          <CLabel>Paste Link for Upload Image 4</CLabel>
          <CInput
            name="image1"
            value={image4}
            placeholder="Productimage4"
            aria-label="Productimage1"
            onChange={(e) => setImage4(e.target.value)}
          />
        </CCol>
        {image4 && (
          <CCol xs={6} style={{ padding: "29px 0px" }}>
            <img
              // src={value?.image1}
              src={image4}
              height={200}
              width={200}
              alt="Image"
            />
          </CCol>
        )}
      </CRow>

      <CRow>
        <CCol xs={6} style={{ padding: "30px 30px" }}>
          <CLabel>Paste Link for Upload Image 5</CLabel>
          <CInput
            name="image1"
            value={image5}
            placeholder="Productimage5"
            aria-label="Productimage1"
            onChange={(e) => setImage5(e.target.value)}
          />
        </CCol>
        {image5 && (
          <CCol xs={6} style={{ padding: "29px 0px" }}>
            <img
              // src={value?.image1}
              src={image5}
              height={200}
              width={200}
              alt="Image"
            />
          </CCol>
        )}
      </CRow>

      <CRow>
        <CCol xs={6} style={{ padding: "30px 30px" }}>
          <CLabel>Paste Link for Upload Image 6</CLabel>
          <CInput
            name="image1"
            value={image6}
            placeholder="Productimage6"
            aria-label="Productimage1"
            onChange={(e) => setImage6(e.target.value)}
          />
        </CCol>
        {image6 && (
          <CCol xs={6} style={{ padding: "29px 0px" }}>
            <img
              // src={value?.image1}
              src={image6}
              height={200}
              width={200}
              alt="Image"
            />
          </CCol>
        )}
      </CRow>

      <CRow>
        <CCol xs={6} style={{ padding: "30px 30px" }}>
          <CLabel>Paste Link for Upload Image 7</CLabel>
          <CInput
            name="image1"
            value={image7}
            placeholder="Productimage7"
            aria-label="Productimage1"
            // onChange={HandleChange}
            onChange={(e) => setImage7(e.target.value)}
          />
        </CCol>
        {image7 && (
          <CCol xs={6} style={{ padding: "29px 0px" }}>
            <img
              // src={value?.image1}
              src={image7}
              height={200}
              width={200}
              alt="Image"
            />
          </CCol>
        )}
      </CRow>

      <CRow>
        <CCol xs={6} style={{ padding: "30px 30px" }}>
          <CLabel>Paste Link for Upload Image 8</CLabel>
          <CInput
            name="image1"
            value={image8}
            placeholder="Productimage8"
            aria-label="Productimage1"
            // onChange={HandleChange}
            onChange={(e) => setImage8(e.target.value)}
          />
        </CCol>
        {image8 && (
          <CCol xs={6} style={{ padding: "29px 0px" }}>
            <img
              // src={value?.image1}
              src={image8}
              height={200}
              width={200}
              alt="Image"
            />
          </CCol>
        )}
      </CRow>

      <CCol xs="12" sm="12" md="12" className="text-end">
        <CButton color="success " className="mt-3 " onClick={handleUpdate}>
          Update
        </CButton>
      </CCol>
    </>
  );
};

const FeatureUpdate = () => {
  const singleData = useSelector((state) => state?.user?.SingleproductbyCat);
  const [value, setValue] = useState({
    featureProduct: "",
    featureField: "",
  });

  useEffect(() => {
    setValue({
      featureProduct: singleData?.featureProduct || false,
      featureField: singleData?.featureField || "",
    });
  }, [singleData]);

  const onChangeHandle = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdate = async () => {
    const choice = window.confirm("Are you sure you want to Update Data?");
    if (choice) {
      try {
        const res = await API.put(
          `admin/updateProduct/${singleData._id}`,
          value
        );
        alert("Update Successful");
      } catch (error) {
        alert("Error updating product");
      }
    }
  };
  return (
    <CRow>
      <CCol xs="12" sm="12" md="6">
        <CLabel className="m-1">Product SKU</CLabel>
        <CInput
          //   onChange={HandleChange}
          name="discountName"
          //   value={data.discountName}
          value={singleData?.sku}
          disabled
        ></CInput>
      </CCol>

      <CCol xs="12" sm="12" md="6">
        <CLabel className="m-1 pb-1">Status</CLabel>
        <FormControl
          fullWidth
          variant="outlined"
          size="small"
          // style={{ margin: "0 12px", width: "100%" }}
        >
          <Select
            labelId="demo-simple-select-subCategory"
            id="demo-simple-select-subCategory"
            name="featureProduct"
            value={`${value.featureProduct}`}
            onChange={(e) => onChangeHandle(e)}
          >
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
          </Select>
        </FormControl>
      </CCol>

      <CCol xs="12" sm="12" md="6">
        <CLabel className="m-1 pb-1">Feature</CLabel>
        <FormControl
          fullWidth
          variant="outlined"
          size="small"
          // style={{ margin: "0 12px", width: "100%" }}
        >
          <Select
            labelId="demo-simple-select-subCategory"
            id="demo-simple-select-subCategory"
            name="featureField"
            value={`${value.featureField}`}
            onChange={(e) => onChangeHandle(e)}
          >
            <MenuItem value="">No Select</MenuItem>
            <MenuItem value="Top Selling">Top Selling</MenuItem>
            <MenuItem value="Express Shipping">Express Shipping</MenuItem>
            <MenuItem value="New Arrival">New Arrival</MenuItem>
            <MenuItem value="Trending">Trending</MenuItem>
            <MenuItem value="Sale">Sale</MenuItem>
            <MenuItem value="Best Seller">Best Seller</MenuItem>
          </Select>
        </FormControl>
      </CCol>

      <CCol xs="12" sm="12" md="12" className="text-end">
        <CButton color="success " className="mt-3 " onClick={handleUpdate}>
          Update
        </CButton>
      </CCol>
    </CRow>
  );
};

// Main Export funcation Start here
const EditSingleProduct = ({ setEditPage, parentSku }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [collapsed, setCollapsed] = React.useState(true);
  const [showCard, setShowCard] = React.useState(true);
  const [color, setColor] = useState("");
  const dispatch = useDispatch();

  console.log(parentSku);

  useEffect(() => {
    let body = {
      parentSku: parentSku,
    };
    if (color !== "") {
      body.color = color;
    }
    dispatch(fetchsingleproduct(body));
  }, [parentSku, activeTab]);

  return (
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader
            onClick={() => setEditPage(false)}
            style={{ cursor: "pointer" }}
          >
            Back
          </CCardHeader>
          <CRow className="mt-2 ps-1">
            <CCol xs="3">
              <CListGroup id="list-tab" role="tablist">
                <CListGroupItem
                  onClick={() => {
                    setActiveTab(0);
                    setColor("");
                  }}
                  action
                  active={activeTab === 0}
                >
                  Edit Product
                </CListGroupItem>
                <CListGroupItem
                  onClick={() => {
                    setActiveTab(1);
                    setColor("rose");
                  }}
                  action
                  active={activeTab === 1}
                >
                  Edit Rose Gold Image
                </CListGroupItem>
                <CListGroupItem
                  onClick={() => {
                    setActiveTab(2);
                    setColor("white");
                  }}
                  action
                  active={activeTab === 2}
                >
                  Edit White Gold Image
                </CListGroupItem>
                <CListGroupItem
                  onClick={() => {
                    setActiveTab(3);
                    setColor("yellow");
                  }}
                  action
                  active={activeTab === 3}
                >
                  Edit Yellow Gold Image
                </CListGroupItem>
                <CListGroupItem
                  onClick={() => {
                    setActiveTab(4);
                  }}
                  action
                  active={activeTab === 4}
                >
                  Feature Product
                </CListGroupItem>
                <CListGroupItem
                  onClick={() => {
                    setActiveTab(5);
                  }}
                  action
                  active={activeTab === 5}
                >
                  Add User Discount
                </CListGroupItem>
                <CListGroupItem
                  onClick={() => {
                    setActiveTab(6);
                  }}
                  action
                  active={activeTab === 6}
                >
                  Add General Discount
                </CListGroupItem>
              </CListGroup>
            </CCol>

            <CCol xs="9" style={{ marginLeft: "-10px" }}>
              <CTabContent>
                <CTabPane active={activeTab === 0}>
                  <p>
                    <CListGroup>
                      <CListGroupItem action>
                        <CRow>
                          <CCol xs="12" sm="12" md="12">
                            <CFade in={showCard}>
                              <CCard>
                                <CCollapse show={collapsed}>
                                  <CCardBody>
                                    <ProductInfoUpdate />
                                  </CCardBody>
                                </CCollapse>
                              </CCard>
                            </CFade>
                          </CCol>
                        </CRow>
                      </CListGroupItem>
                    </CListGroup>
                  </p>
                </CTabPane>

                <CTabPane active={activeTab === 1}>
                  <p>
                    <CListGroup>
                      <CListGroupItem action>
                        <CRow>
                          <CCol xs="12" sm="12" md="12">
                            <CFade in={showCard}>
                              <CCard>
                                <CCollapse show={collapsed}>
                                  <CCardBody>
                                    <ImageUpdate color={color} />
                                  </CCardBody>
                                </CCollapse>
                              </CCard>
                            </CFade>
                          </CCol>
                        </CRow>
                      </CListGroupItem>
                    </CListGroup>
                  </p>
                </CTabPane>

                <CTabPane active={activeTab === 2}>
                  <p>
                    <CListGroup>
                      <CListGroupItem action>
                        <CRow>
                          <CCol xs="12" sm="12" md="12">
                            <CFade in={showCard}>
                              <CCard>
                                <CCollapse show={collapsed}>
                                  <CCardBody>
                                    <ImageUpdate color={color} />
                                  </CCardBody>
                                </CCollapse>
                              </CCard>
                            </CFade>
                          </CCol>
                        </CRow>
                      </CListGroupItem>
                    </CListGroup>
                  </p>
                </CTabPane>

                <CTabPane active={activeTab === 3}>
                  <p>
                    <CListGroup>
                      <CListGroupItem action>
                        <CRow>
                          <CCol xs="12" sm="12" md="12">
                            <CFade in={showCard}>
                              <CCard>
                                <CCollapse show={collapsed}>
                                  <CCardBody>
                                    <ImageUpdate color={color} />
                                  </CCardBody>
                                </CCollapse>
                              </CCard>
                            </CFade>
                          </CCol>
                        </CRow>
                      </CListGroupItem>
                    </CListGroup>
                  </p>
                </CTabPane>

                <CTabPane active={activeTab === 4}>
                  <p>
                    <CListGroup>
                      <CListGroupItem action>
                        <CRow>
                          <CCol xs="12" sm="12" md="12">
                            <CFade in={showCard}>
                              <CCard>
                                <CCollapse show={collapsed}>
                                  <CCardBody>
                                    <FeatureUpdate />
                                  </CCardBody>
                                </CCollapse>
                              </CCard>
                            </CFade>
                          </CCol>
                        </CRow>
                      </CListGroupItem>
                    </CListGroup>
                  </p>
                </CTabPane>
                <CTabPane active={activeTab === 5}>
                  <p>
                    <CListGroup>
                      <CListGroupItem action>
                        <CRow>
                          <CCol xs="12" sm="12" md="12">
                            <CFade in={showCard}>
                              <CCard>
                                <CCollapse show={collapsed}>
                                  <CCardBody>
                                    <AddProductBasedCoupon
                                      parentSku={parentSku}
                                    />
                                  </CCardBody>
                                </CCollapse>
                              </CCard>
                            </CFade>
                          </CCol>
                        </CRow>
                      </CListGroupItem>
                    </CListGroup>
                  </p>
                </CTabPane>
                <CTabPane active={activeTab === 6}>
                  <p>
                    <CListGroup>
                      <CListGroupItem action>
                        <CRow>
                          <CCol xs="12" sm="12" md="12">
                            <CFade in={showCard}>
                              <CCard>
                                <CCollapse show={collapsed}>
                                  <CCardBody>
                                    <AddProductBasedGerneralCoupon
                                      parentSku={parentSku}
                                    />
                                  </CCardBody>
                                </CCollapse>
                              </CCard>
                            </CFade>
                          </CCol>
                        </CRow>
                      </CListGroupItem>
                    </CListGroup>
                  </p>
                </CTabPane>
              </CTabContent>
            </CCol>
          </CRow>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default EditSingleProduct;
