import axios from "axios";
axios.defaults.withCredentials = true;

const userToken = localStorage.getItem("userToken");

const API = axios.create({
  // baseURL: "https://api.caratglitz.com/",
  baseURL: process.env.REACT_APP_API_URL + "/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Token: ` ${userToken}`,
  },
});

const APIFormData = axios.create({
  // baseURL: "https://api.caratglitz.com/",
  baseURL: process.env.REACT_APP_API_URL + "/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Token: ` ${userToken}`,
    "Content-Type": "multipart/form-data",
  },
});

// const baseURL = "https://api.caratglitz.com/";
const baseURL = process.env.REACT_APP_API_URL + "/";

function get(url) {
  return API.get(baseURL + url);
}

function post(url, obj) {
  return API.post(baseURL + url, obj);
}

function deleteData(url) {
  return API.delete(baseURL + url);
}
function patch(url, obj) {
  return API.patch(baseURL + url, obj);
}
function put(url, obj) {
  return API.put(baseURL + url, obj);
}
function postFormData(url, obj) {
  return APIFormData.post(baseURL + url, obj);
}
function putFormData(url, obj) {
  return APIFormData.put(baseURL + url, obj);
}

export default {
  get,
  post,
  deleteData,
  patch,
  put,
  postFormData,
  putFormData,
};
