import React from "react";
import CIcon from "@coreui/icons-react";
import style from "../containers/sidebar.module.css";
import { useSelector } from "react-redux";

const CustomIcon = () => {
  return (
    <div>
      {/* Your custom icon JSX */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-custom"
        viewBox="0 0 16 16"
      >
        <path d="M0 0h16v16H0z" />
      </svg>
    </div>
  );
};
export const navbar = (navItem) => {
  //  const navItem =   useSelector((state)=> state?.user?.navbarData)
  return {
    _tag: "CSidebarNavDropdown",
    name: "All Products",
    route: "/base2",
    icon: "cil-basket",

    _children: navItem.map((item) => {
      var cat = item.category;
      if (item.SubmenuData) {
        return {
          _tag: "CSidebarNavDropdown",
          name: `${item.category}`,
          route: "/base3",
          icon: "cil-basket",

          _children: item.SubmenuData.map((submenu) => {
            return {
              _tag: "CSidebarNavItem",
              name: `${submenu.subCategory}`,
              to: `/admin/products/${cat}/${submenu.subCategory}`,
            };
          }),
        };
      } else {
        return {
          _tag: "CSidebarNavItem",
          name: `${item.category}`,
          to: `/admin/products/${item.category}`,
        };
      }
    }),
  };
};

const _nav = [
  {
    _tag: "CSidebarNavItem",
    name: "Dashboard",
    to: "/admin/dashboard",
    icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
    badge: {
      color: "info",
    },
  },

  // ===============================New
  // ======================Product
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Product",
  //   to: "/admin/Product",
  //   icon: "cil-puzzle",
  //   badge: {
  //     color: "info",
  //   },

  // },

  {
    _tag: "CSidebarNavDropdown",
    name: "Products",
    route: "/base",
    icon: (
      <svg
        className={`${style.mar}`}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 2048 2048"
      >
        <path
          fill="currentColor"
          d="m1344 2l704 352v785l-128-64V497l-512 256v258l-128 64V753L768 497v227l-128-64V354L1344 2zm0 640l177-89l-463-265l-211 106l497 248zm315-157l182-91l-497-249l-149 75l464 265zm-507 654l-128 64v-1l-384 192v455l384-193v144l-448 224L0 1735v-676l576-288l576 288v80zm-640 710v-455l-384-192v454l384 193zm64-566l369-184l-369-185l-369 185l369 184zm576-1l448-224l448 224v527l-448 224l-448-224v-527zm384 576v-305l-256-128v305l256 128zm384-128v-305l-256 128v305l256-128zm-320-288l241-121l-241-120l-241 120l241 121z"
        />
      </svg>
    ),
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Products",
        to: "/admin/Product",
        icone: (
          <svg
            className={`${style.mar}`}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 2048 2048"
          >
            <path
              fill="currentColor"
              d="m960 120l832 416v1040l-832 415l-832-415V536l832-416zm625 456L960 264L719 384l621 314l245-122zM960 888l238-118l-622-314l-241 120l625 312zM256 680v816l640 320v-816L256 680zm768 1136l640-320V680l-640 320v816z"
            />
          </svg>
        ),
      },
      {
        _tag: "CSidebarNavItem",
        name: "Product Category",
        to: "/admin/Category",
      },
      {
        _tag: "CSidebarNavItem",
        name: " Demand Products",
        to: "/admin/HighDemandProduct",
      },
      {
        _tag: "CSidebarNavItem",
        name: " Feature Products",
        to: "/admin/FeatureProduct",
      },
    ],
  },

  {
    _tag: "CSidebarNavItem",
    name: "All Products",
    icon: (
      <svg
        className={`${style.mar}`}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M12.89 11.1c-1.78-.59-2.64-.96-2.64-1.9c0-1.02 1.11-1.39 1.81-1.39c1.31 0 1.79.99 1.9 1.34l1.58-.67c-.15-.45-.82-1.92-2.54-2.24V5h-2v1.26c-2.48.56-2.49 2.86-2.49 2.96c0 2.27 2.25 2.91 3.35 3.31c1.58.56 2.28 1.07 2.28 2.03c0 1.13-1.05 1.61-1.98 1.61c-1.82 0-2.34-1.87-2.4-2.09l-1.66.67c.63 2.19 2.28 2.78 2.9 2.96V19h2v-1.24c.4-.09 2.9-.59 2.9-3.22c0-1.39-.61-2.61-3.01-3.44zM3 21H1v-6h6v2H4.52c1.61 2.41 4.36 4 7.48 4a9 9 0 0 0 9-9h2c0 6.08-4.92 11-11 11c-3.72 0-7.01-1.85-9-4.67V21zm-2-9C1 5.92 5.92 1 12 1c3.72 0 7.01 1.85 9 4.67V3h2v6h-6V7h2.48C17.87 4.59 15.12 3 12 3a9 9 0 0 0-9 9H1z"
        />
      </svg>
    ),
    badge: {
      color: "info",
    },
  },
  // ======================Update Price
  {
    _tag: "CSidebarNavItem",
    name: "Update Price",
    to: "/admin/update",
    icon: (
      <svg
        className={`${style.mar}`}
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M8 17h2v-1h2v-5H8v-1h4V8h-2V7H8v1H6v5h4v1H6v2h2v1Zm8-.75l2-2h-4l2 2ZM14 10h4l-2-2l-2 2ZM2 20V4h20v16H2Zm2-2h16V6H4v12Zm0 0V6v12Z"
        />
      </svg>
    ),
    badge: {
      color: "info",
    },
  },

  // ======================currnecy Price
  {
    _tag: "CSidebarNavItem",
    name: "Currency Update",
    to: "/admin/CurrencyUpdate",
    icon: (
      <svg
        className={`${style.mar}`}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M12.89 11.1c-1.78-.59-2.64-.96-2.64-1.9c0-1.02 1.11-1.39 1.81-1.39c1.31 0 1.79.99 1.9 1.34l1.58-.67c-.15-.45-.82-1.92-2.54-2.24V5h-2v1.26c-2.48.56-2.49 2.86-2.49 2.96c0 2.27 2.25 2.91 3.35 3.31c1.58.56 2.28 1.07 2.28 2.03c0 1.13-1.05 1.61-1.98 1.61c-1.82 0-2.34-1.87-2.4-2.09l-1.66.67c.63 2.19 2.28 2.78 2.9 2.96V19h2v-1.24c.4-.09 2.9-.59 2.9-3.22c0-1.39-.61-2.61-3.01-3.44zM3 21H1v-6h6v2H4.52c1.61 2.41 4.36 4 7.48 4a9 9 0 0 0 9-9h2c0 6.08-4.92 11-11 11c-3.72 0-7.01-1.85-9-4.67V21zm-2-9C1 5.92 5.92 1 12 1c3.72 0 7.01 1.85 9 4.67V3h2v6h-6V7h2.48C17.87 4.59 15.12 3 12 3a9 9 0 0 0-9 9H1z"
        />
      </svg>
    ),
    badge: {
      color: "info",
    },
  },

  // Order List with All Details in one place

  {
    _tag: "CSidebarNavDropdown",
    name: "All Orders",
    route: "/base",
    icon: (
      <svg
        className={`${style.mar}`}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M22 21H2V3h2v16h2v-9h4v9h2V6h4v13h2v-5h4v7Z"
        />
      </svg>
    ),
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Pending Orders",
        to: "/admin/orderconfirmed",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Transit Orders",
        to: "/admin/orderTransit",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Delivered Orders",
        to: "/admin/Orderdelivered",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Return Orders",
        to: "/admin/returndOrder",
      },

      {
        _tag: "CSidebarNavItem",
        name: "Refund Orders",
        to: "/admin/refundOrder",
      },
      {
        _tag: "CSidebarNavItem",
        name: "All Orders",
        to: "/admin/AllOrder",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Cancel Orders",
        to: "/admin/cancelOrder",
      },
    ],
  },

  // ===========================Order
  // {
  //   _tag: "CSidebarNavDropdown",
  //   name: "Reports",
  //   route: "/base",
  //   icon: (
  //     <svg
  //       className={`${style.mar}`}
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="20"
  //       height="20"
  //       viewBox="0 0 24 24"
  //     >
  //       <path
  //         fill="currentColor"
  //         d="M22 21H2V3h2v16h2v-9h4v9h2V6h4v13h2v-5h4v7Z"
  //       />
  //     </svg>
  //   ),
  //   _children: [
  //     {
  //       _tag: "CSidebarNavItem",
  //       name: "Order Report",
  //       to: "/admin/OrderDetails",
  //     },
  //   ],
  // },
  // ===========================Order
  // {
  //   _tag: "CSidebarNavDropdown",
  //   name: "Inventory",
  //   route: "/base",
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       className={`${style.mar}`}
  //       width="25"
  //       height="25"
  //       viewBox="0 0 48 48"
  //     >
  //       <g fill="currentColor">
  //         <path d="M27.707 7.707a1 1 0 0 0-1.414-1.414L24 8.586l-2.293-2.293a1 1 0 1 0-1.414 1.414L22.586 10l-2.293 2.293a1 1 0 0 0 1.414 1.414L24 11.414l2.293 2.293a1 1 0 1 0 1.414-1.415L25.414 10l2.293-2.293Zm6.242 24.477a1 1 0 0 1-.633 1.265l-4.5 1.5a1 1 0 0 1-.632-1.898l4.5-1.5a1 1 0 0 1 1.265.633Z" />
  //         <path
  //           fillRule="evenodd"
  //           d="M6.684 26.449L10 27.554V36a1 1 0 0 0 .673.945l12.992 4.497a.99.99 0 0 0 .637.011l.014-.004l.015-.005l12.996-4.499A1 1 0 0 0 38 36v-8.446l3.316-1.105a1 1 0 0 0 .465-1.574l-4-5a1 1 0 0 0-.456-.32l-12.998-4.5a1 1 0 0 0-.654 0l-12.998 4.5a.999.999 0 0 0-.456.32l-4 5a1 1 0 0 0 .465 1.574Zm14.635 4.124l1.681-2.4v10.923l-11-3.808V28.22l8.184 2.728a1 1 0 0 0 1.135-.376ZM14.057 20.5L24 23.942l9.943-3.442L24 17.058L14.057 20.5Zm12.624 10.073L25 28.174v10.923l11-3.808V28.22l-8.184 2.728a1 1 0 0 1-1.135-.376ZM11.34 21.676l-2.663 3.329l5.511 1.837l5.92 1.973l2.313-3.303l-.135-.047l-10.946-3.79Zm27.983 3.329l-2.663-3.33l-11.081 3.837l2.313 3.303l11.431-3.81Z"
  //           clipRule="evenodd"
  //         />
  //       </g>
  //     </svg>
  //   ),
  //   _children: [

  //     {
  //       _tag: "CSidebarNavItem",
  //       name: "Deilvered Product",
  //       to: "/admin/DeilveredProduct",
  //     },
  //   ],
  // },
  // ===========================Sales
  // {
  //   _tag: "CSidebarNavDropdown",
  //   name: "Sales",
  //   route: "/base",
  //   icon: (
  //     <svg
  //       className={`${style.mar}`}
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="20"
  //       height="20"
  //       viewBox="0 0 14 14"
  //     >
  //       <g
  //         fill="none"
  //         stroke="currentColor"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //       >
  //         <rect
  //           width="13"
  //           height="13"
  //           x=".5"
  //           y=".5"
  //           rx="1"
  //           transform="rotate(180 7 7)"
  //         />
  //         <path d="M3 3h2M3 5.5h4.5m4 0l-3 5l-3.5-2l-2 3" />
  //       </g>
  //     </svg>
  //   ),
  //   _children: [

  //     {
  //       _tag: "CSidebarNavItem",
  //       name: "All Sale",
  //       to: "/admin/AllSales",
  //     },
  //   ],
  // },
  // ===========================Sales coupon
  {
    _tag: "CSidebarNavDropdown",
    name: "Coupons",
    route: "/base",
    icon: (
      <svg
        height="24"
        viewBox="0 0 24 24"
        width="24"
        className={`${style.mar}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          d="M15,6 C15,6.55228475 14.5522847,7 14,7 C13.4477153,7 13,6.55228475 13,6 L3,6 L3,7.99946819 C4.2410063,8.93038753 5,10.3994926 5,12 C5,13.6005074 4.2410063,15.0696125 3,16.0005318 L3,18 L13,18 C13,17.4477153 13.4477153,17 14,17 C14.5522847,17 15,17.4477153 15,18 L21,18 L21,16.0005318 C19.7589937,15.0696125 19,13.6005074 19,12 C19,10.3994926 19.7589937,8.93038753 21,7.99946819 L21,6 L15,6 Z M23,18 C23,19.1045695 22.1045695,20 21,20 L3,20 C1.8954305,20 1,19.1045695 1,18 L1,14.8880798 L1.49927404,14.5992654 C2.42112628,14.0660026 3,13.0839642 3,12 C3,10.9160358 2.42112628,9.93399737 1.49927404,9.40073465 L1,9.11192021 L1,6 C1,4.8954305 1.8954305,4 3,4 L21,4 C22.1045695,4 23,4.8954305 23,6 L23,9.11192021 L22.500726,9.40073465 C21.5788737,9.93399737 21,10.9160358 21,12 C21,13.0839642 21.5788737,14.0660026 22.500726,14.5992654 L23,14.8880798 L23,18 Z M14,16 C13.4477153,16 13,15.5522847 13,15 C13,14.4477153 13.4477153,14 14,14 C14.5522847,14 15,14.4477153 15,15 C15,15.5522847 14.5522847,16 14,16 Z M14,13 C13.4477153,13 13,12.5522847 13,12 C13,11.4477153 13.4477153,11 14,11 C14.5522847,11 15,11.4477153 15,12 C15,12.5522847 14.5522847,13 14,13 Z M14,10 C13.4477153,10 13,9.55228475 13,9 C13,8.44771525 13.4477153,8 14,8 C14.5522847,8 15,8.44771525 15,9 C15,9.55228475 14.5522847,10 14,10 Z"
          fill-rule="evenodd"
        />
      </svg>
    ),

    _children: [
      {
        _tag: "CSidebarNavDropdown",
        name: "General Discount",
        // to: "/admin/CoupanList",
        route: "/base4",
        icon: (
          <svg
            height="24"
            viewBox="0 0 24 24"
            width="24"
            className={`${style.mar}`}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="currentColor"
              d="M15,6 C15,6.55228475 14.5522847,7 14,7 C13.4477153,7 13,6.55228475 13,6 L3,6 L3,7.99946819 C4.2410063,8.93038753 5,10.3994926 5,12 C5,13.6005074 4.2410063,15.0696125 3,16.0005318 L3,18 L13,18 C13,17.4477153 13.4477153,17 14,17 C14.5522847,17 15,17.4477153 15,18 L21,18 L21,16.0005318 C19.7589937,15.0696125 19,13.6005074 19,12 C19,10.3994926 19.7589937,8.93038753 21,7.99946819 L21,6 L15,6 Z M23,18 C23,19.1045695 22.1045695,20 21,20 L3,20 C1.8954305,20 1,19.1045695 1,18 L1,14.8880798 L1.49927404,14.5992654 C2.42112628,14.0660026 3,13.0839642 3,12 C3,10.9160358 2.42112628,9.93399737 1.49927404,9.40073465 L1,9.11192021 L1,6 C1,4.8954305 1.8954305,4 3,4 L21,4 C22.1045695,4 23,4.8954305 23,6 L23,9.11192021 L22.500726,9.40073465 C21.5788737,9.93399737 21,10.9160358 21,12 C21,13.0839642 21.5788737,14.0660026 22.500726,14.5992654 L23,14.8880798 L23,18 Z M14,16 C13.4477153,16 13,15.5522847 13,15 C13,14.4477153 13.4477153,14 14,14 C14.5522847,14 15,14.4477153 15,15 C15,15.5522847 14.5522847,16 14,16 Z M14,13 C13.4477153,13 13,12.5522847 13,12 C13,11.4477153 13.4477153,11 14,11 C14.5522847,11 15,11.4477153 15,12 C15,12.5522847 14.5522847,13 14,13 Z M14,10 C13.4477153,10 13,9.55228475 13,9 C13,8.44771525 13.4477153,8 14,8 C14.5522847,8 15,8.44771525 15,9 C15,9.55228475 14.5522847,10 14,10 Z"
              fill-rule="evenodd"
            />
          </svg>
        ),
        _children: [
          {
            _tag: "CSidebarNavItem",
            name: "Category Discount",
            to: "/admin/General/Discount",
          },
          {
            _tag: "CSidebarNavItem",
            name: "Product Discount",
            to: "/admin/General/ProductBasedCoupon",
          },
          {
            _tag: "CSidebarNavItem",
            name: "All Product Discount",
            to: "/admin/General/Useralldiscount",
          },
          {
            _tag: "CSidebarNavItem",
            name: "Diamond Discount",
            to: "/admin/General/Useralldiamonddiscount",
          },
        ],
      },
      {
        _tag: "CSidebarNavDropdown",
        name: "User Discount",
        // to: "/admin/CoupanList",
        route: "/base5",
        icon: (
          <svg
            height="24"
            viewBox="0 0 24 24"
            width="24"
            className={`${style.mar}`}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="currentColor"
              d="M15,6 C15,6.55228475 14.5522847,7 14,7 C13.4477153,7 13,6.55228475 13,6 L3,6 L3,7.99946819 C4.2410063,8.93038753 5,10.3994926 5,12 C5,13.6005074 4.2410063,15.0696125 3,16.0005318 L3,18 L13,18 C13,17.4477153 13.4477153,17 14,17 C14.5522847,17 15,17.4477153 15,18 L21,18 L21,16.0005318 C19.7589937,15.0696125 19,13.6005074 19,12 C19,10.3994926 19.7589937,8.93038753 21,7.99946819 L21,6 L15,6 Z M23,18 C23,19.1045695 22.1045695,20 21,20 L3,20 C1.8954305,20 1,19.1045695 1,18 L1,14.8880798 L1.49927404,14.5992654 C2.42112628,14.0660026 3,13.0839642 3,12 C3,10.9160358 2.42112628,9.93399737 1.49927404,9.40073465 L1,9.11192021 L1,6 C1,4.8954305 1.8954305,4 3,4 L21,4 C22.1045695,4 23,4.8954305 23,6 L23,9.11192021 L22.500726,9.40073465 C21.5788737,9.93399737 21,10.9160358 21,12 C21,13.0839642 21.5788737,14.0660026 22.500726,14.5992654 L23,14.8880798 L23,18 Z M14,16 C13.4477153,16 13,15.5522847 13,15 C13,14.4477153 13.4477153,14 14,14 C14.5522847,14 15,14.4477153 15,15 C15,15.5522847 14.5522847,16 14,16 Z M14,13 C13.4477153,13 13,12.5522847 13,12 C13,11.4477153 13.4477153,11 14,11 C14.5522847,11 15,11.4477153 15,12 C15,12.5522847 14.5522847,13 14,13 Z M14,10 C13.4477153,10 13,9.55228475 13,9 C13,8.44771525 13.4477153,8 14,8 C14.5522847,8 15,8.44771525 15,9 C15,9.55228475 14.5522847,10 14,10 Z"
              fill-rule="evenodd"
            />
          </svg>
        ),
        _children: [
          {
            _tag: "CSidebarNavItem",
            name: "Category Discount",
            to: "/admin/Discount",
          },
          {
            _tag: "CSidebarNavItem",
            name: "Product Discount",
            to: "/admin/ProductBasedCoupon",
          },
          {
            _tag: "CSidebarNavItem",
            name: "All Product Discount",
            to: "/admin/Useralldiscount",
          },
          {
            _tag: "CSidebarNavItem",
            name: "Diamond Discount",
            to: "/admin/Useralldiamonddiscount",
          },
        ],
      },
    ],
  },

  ////////// GST REPORT AND REPORT SECTION

  {
    _tag: "CSidebarNavDropdown",
    name: "Report",
    route: "/base",
    icon: (
      <svg
        className={`${style.mar}`}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 14 14"
      >
        <g
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <rect
            width="13"
            height="13"
            x=".5"
            y=".5"
            rx="1"
            transform="rotate(180 7 7)"
          />
          <path d="M3 3h2M3 5.5h4.5m4 0l-3 5l-3.5-2l-2 3" />
        </g>
      </svg>
    ),
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Gst Report",
        to: "/admin/gstreport",
      },
    ],
  },

  // =========================customers
  {
    _tag: "CSidebarNavItem",
    name: "Customers",
    to: "/admin/CustomerList",
    icon: (
      <svg
        className={`${style.mar}`}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5s-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5S5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05c1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"
        />
      </svg>
    ),
    badge: {
      color: "info",
    },
  },

  {
    _tag: "CSidebarNavItem",
    name: "Support",
    to: "/admin/support",
    icon: (
      <svg
        className={`${style.mar}`}
        height="22"
        viewBox="0 0 22 22"
        width="22"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          d="M12 2C6.486 2 2 6.486 2 12v4.143C2 17.167 2.897 18 4 18h1a1 1 0 0 0 1-1v-5.143a1 1 0 0 0-1-1h-.908C4.648 6.987 7.978 4 12 4s7.352 2.987 7.908 6.857H19a1 1 0 0 0-1 1V18c0 1.103-.897 2-2 2h-2v-1h-4v3h6c2.206 0 4-1.794 4-4 1.103 0 2-.833 2-1.857V12c0-5.514-4.486-10-10-10z"
        />
      </svg>
    ),
    badge: {
      color: "info",
    },
  },
  // =========================customers
  {
    _tag: "CSidebarNavItem",
    name: "WishList",
    to: "/admin/WishList",
    icon: (
      <svg
        className={`${style.mar}`}
        data-name="Layer 4"
        id="Layer_4"
        viewBox="0 0 128 128"
        height="24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          d="M80.79,28.43A21.2,21.2,0,0,1,102,49.61c0,8.12-3.52,20.9-20.26,35.67A120.67,120.67,0,0,1,64,98.11,120.67,120.67,0,0,1,46.29,85.28C29.55,70.51,26,57.73,26,49.61A21.18,21.18,0,0,1,58.61,31.76L64,35.22l5.39-3.46a21.12,21.12,0,0,1,11.4-3.33m0-10A31.08,31.08,0,0,0,64,23.34,31.18,31.18,0,0,0,16,49.61c0,36,48,60,48,60s48-24,48-60A31.18,31.18,0,0,0,80.79,18.43Z"
        />
      </svg>
    ),
    badge: {
      color: "info",
    },
  },

  // ====================Website=============

  {
    _tag: "CSidebarNavDropdown",
    name: "Website",
    route: "/base",
    icon: (
      <svg
        className={`${style.mar}`}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M16.36 14c.08-.66.14-1.32.14-2c0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2m-5.15 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 0 1-4.33 3.56M14.34 14H9.66c-.1-.66-.16-1.32-.16-2c0-.68.06-1.35.16-2h4.68c.09.65.16 1.32.16 2c0 .68-.07 1.34-.16 2M12 19.96c-.83-1.2-1.5-2.53-1.91-3.96h3.82c-.41 1.43-1.08 2.76-1.91 3.96M8 8H5.08A7.923 7.923 0 0 1 9.4 4.44C8.8 5.55 8.35 6.75 8 8m-2.92 8H8c.35 1.25.8 2.45 1.4 3.56A8.008 8.008 0 0 1 5.08 16m-.82-2C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2c0 .68.06 1.34.14 2M12 4.03c.83 1.2 1.5 2.54 1.91 3.97h-3.82c.41-1.43 1.08-2.77 1.91-3.97M18.92 8h-2.95a15.65 15.65 0 0 0-1.38-3.56c1.84.63 3.37 1.9 4.33 3.56M12 2C6.47 2 2 6.5 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2Z"
        />
      </svg>
    ),
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Home Slider",
        to: "/admin/banner",
      },

      {
        _tag: "CSidebarNavItem",
        name: "Product Banner",
        to: "/admin/ProductBannerUpload",
      },

      {
        _tag: "CSidebarNavItem",
        name: "Category Image",
        to: "/admin/CategoryImage",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Shop By Category",
        to: "/admin/shopbyCategory",
      },

      {
        _tag: "CSidebarNavItem",
        name: "Contact Us",
        to: "/admin/ContactUs",
      },

      // {
      //   _tag: "CSidebarNavItem",
      //   name: "Text Editior",
      //   to: "/admin/TextEditior",
      // },
    ],
  },

  {
    _tag: "CSidebarNavItem",
    name: "Rating & Review",
    to: "/admin/rating",
    icon: (
      <svg
        className={`${style.mar}`}
        height="20"
        id="icon"
        viewBox="0 0 32 32"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon
          fill="currentColor"
          points="16 8 17.912 11.703 22 12.297 19 15 20 19 16 16.75 12 19 13 15 10 12.297 14.2 11.703 16 8"
        />
        <path
          fill="currentColor"
          d="M17.7358,30,16,29l4-7h6a1.9966,1.9966,0,0,0,2-2V8a1.9966,1.9966,0,0,0-2-2H6A1.9966,1.9966,0,0,0,4,8V20a1.9966,1.9966,0,0,0,2,2h9v2H6a3.9993,3.9993,0,0,1-4-4V8A3.9988,3.9988,0,0,1,6,4H26a3.9988,3.9988,0,0,1,4,4V20a3.9993,3.9993,0,0,1-4,4H21.1646Z"
        />
        <rect height="32" width="32" fill="none" />
      </svg>
    ),
    badge: {
      color: "info",
    },
  },
  // ==================NewsLetter
  {
    _tag: "CSidebarNavItem",
    name: "Newsletter",
    to: "/admin/Newsletter",
    icon: (
      <svg
        className={`${style.mar}`}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 32 32"
      >
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M5 16v8c0 1.5 1.5 3 3 3h16c1.5 0 3-1.5 3-3v-8M5 16h5.5s1 3.5 5.5 3.5s5.5-3.5 5.5-3.5H27M5 16v3.5M5 16l1-5m21 5l-1-5M13.5 9h5m-5 4h5m-9 0V5h13v8"
        />
      </svg>
    ),
    badge: {
      color: "info",
    },
  },

  // =================================
  {
    _tag: "CSidebarNavItem",
    name: "FAQ",
    to: "/admin/Faq",
    icon: (
      <svg
        style={{ enableBackground: "new 0 0 24 24" }}
        className={`${style.mar}`}
        version="1.1"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <style type="text/css">
          {`.st0{display:none;}`}
          {`.st1{display:inline;}`}
          {`.st2{opacity:0.2;fill:none;stroke:#000000;stroke-width:5.000000e-02;stroke-miterlimit:10;}`}
        </style>
        <g className="st0" id="grid_system" />
        <g id="_icons">
          <g>
            <path
              fill="currentColor"
              d="M12,3c-5,0-9,4-9,9c0,1.8,0.6,3.6,1.6,5.1l-1.4,2.4c-0.2,0.3-0.2,0.7,0,1S3.6,21,4,21h8c5,0,9-4,9-9S17,3,12,3z M12,19    H5.8l0.9-1.5c0.2-0.4,0.2-0.8-0.1-1.1C5.6,15.2,5,13.6,5,12c0-3.9,3.1-7,7-7s7,3.1,7,7S15.9,19,12,19z"
            />
            <path
              fill="currentColor"
              d="M12.1,7.3h-0.1c-1,0-1.9,0.5-2.4,1.3C9.2,9.1,9.3,9.7,9.8,10c0.5,0.3,1.1,0.2,1.4-0.3c0.2-0.3,0.5-0.4,0.8-0.4h0.1    c0.5,0,0.9,0.4,0.9,0.9c0,0.4-0.3,0.8-0.6,0.9l-0.7,0.2c-0.4,0.1-0.7,0.5-0.7,0.9V13c0,0.6,0.4,1,1,1c0.5,0,1-0.4,1-0.9    c1.2-0.4,2-1.5,2-2.8C15,8.7,13.7,7.3,12.1,7.3z"
            />
            <circle fill="currentColor" cx="12" cy="16" r="1" />
          </g>
        </g>
      </svg>
    ),
    badge: {
      color: "info",
    },
  },

  {
    _tag: "CSidebarNavDivider",
  },
];

export default _nav;
