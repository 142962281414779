import React, { useEffect, useState } from "react";
import {
 
  CHeaderNavLink,
  CCard,
 
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
   
  CPagination,

  
  CButton,
 
  CRow,
} from "@coreui/react";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { FaRegCheckSquare } from "react-icons/fa";
import { FaCheckSquare } from "react-icons/fa";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.min.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { RatingApiDelete, RatingApiUpdateStatus, getConfirmedOrder, getRatingApi } from "src/Redux/orderSlice";
const { afterToday } =  DateRangePicker;




const GetAllRating = () => {
  const [itemsPerPage, setItemsPerPage] = useState(5)
  const [page , setPage] = useState(1)
  const [filterDate ,setFilterDate] = useState()
  const [searchkey , setSearchKey] = useState('')
      
  const dispatch = useDispatch()
  const location = useLocation();

  const {  pathname  } = location;
  const history = useHistory()
  const rating = useSelector((state)=>state?.order?.rating.data) || []
  const totalpage = useSelector((state) => state?.order?.rating?.totalPage) || 1;

const fields = [
    { key: "SL_NO", label: "SL No", _style: { minWidth: "100px" } },
    { key: "userName", label: "Name", _style: { width: "25%" } },
    { key: "userEmail", label: "Email", _style: { width: "10%" } },
    { key: "status", label: "Status", _style: { width: "10%" } },
    { key: "orderId", label: "Order Id", _style: { minWidth: "250px" } },
    { key: "title", label: "Title", _style: { minWidth: "200px" } },
    { key: "discription", label: "Review", _style: { minWidth: "300px" } },
    { key: "star", label: "Rating", _style: { width: "10%" } },
    { key: "productName", label: "Product Name", _style: { minWidth: "200px" }  },
    { key: "createdAt", label: "Created Date", _style: { width: "10%" } },
    { key: "Action", label: "Action", _style: { width: "10%" } },
  ];
  

  let data = {
    page : page ,
  limits : itemsPerPage,
  
   }
  useEffect(()=>{
  
    if(filterDate?.length===2)
    {
      filterDate[1].setHours(23)
      filterDate[1].setMinutes(59)
      filterDate[1].setSeconds(59)
      data.ltDate = new Date(filterDate[1])
      data.gtDate = filterDate[0].toDateString()
    }

   
  
   
  
    dispatch(getRatingApi(data))
    
  },[itemsPerPage , page , filterDate  ])



  useEffect(() => {
    // Only proceed if searchkey is not empty
    
    if (searchkey) {
 
  
  
      data.searchText = searchkey;

      if(filterDate?.length===2)
      {
        filterDate[1].setHours(23)
        filterDate[1].setMinutes(59)
        filterDate[1].setSeconds(59)
        data.ltDate = new Date(filterDate[1])
        data.gtDate = filterDate[0].toDateString()
      }
    
      // Set a timeout to dispatch after 2000 milliseconds (2 seconds)
      let timer = setTimeout(() => {
        // dispatch(getConfirmedOrder(data))
        dispatch(getRatingApi(data))
      }, 2000);
  
      // Cleanup the previous timer on each searchkey change
      return () => clearTimeout(timer);
    }
  
    // If searchkey is empty, don't dispatch and clean up any existing timers
    // dispatch(getConfirmedOrder(data))
    dispatch(getRatingApi(data))
    return () => {};
  
  }, [searchkey]);


  const Delete_Category = (id) =>{
    const choice = window.confirm(
        "Are you sure you want to delete Data?"
      )

      if(choice)
      {
        dispatch(RatingApiDelete({id:id})).then(()=>dispatch(getRatingApi(data)))
      }
    
  }

  const approvedReview = (item) => {

    let status = item.status === 'Unapproved'?'Approved':'Unapproved'
     let body = {
        status: status,
        id:item._id
     }

     dispatch(RatingApiUpdateStatus(body)).then(()=>dispatch(getRatingApi(data)))
  }

  return (
    <div>
      <CRow>
        <CCol>
          <CCard style={{position:"relative"}}>
            <CCardHeader>
                Rating & Review
           
              
            
            </CCardHeader>
            
            <CCardBody>
            <div style={{position:"absolute", top:"71px",left:"18rem"}}>
                <DateRangePicker format="yyyy-MM-dd" showOneCalendar  placeholder="Select Date Range" value={filterDate} onChange={(e)=>setFilterDate(e)} disabledDate={afterToday()}/>
              </div>
              <CDataTable
                // key={randomValue}
                items={rating}
                fields={fields}
                columnFilter={false}
                tableFilter
                footer={false}
                itemsPerPageSelect
                itemsPerPage={itemsPerPage}
                hover
                sorter
                onPaginationChange={(e)=> setItemsPerPage(e)}
                onTableFilterChange={(item)=>{
                     setSearchKey(item)
                }}
             
              
                scopedSlots={{
                  SL_NO: (item, index) => {
                    return <td className="">{(page - 1) * itemsPerPage + index + 1}</td>;
                  },
                  // productName : (item , index)=>{
                  //    return (
                  //     <td style={{minWidth:'150px'}}>{item?.product[0]?.productName}</td>
                  //    )
                  // },
                  createdAt : (item , index)=>{
                    let date = moment(item.createdAt).format('ll')
                     return (
                      <td style={{minWidth:'150px'}}>{date}</td>
                     )
                  },
                  OrderId: (item, index) => {
                    return (
                      <td style={{ minWidth: "150px" }}>{item.OrderId}</td>
                    );
                  },

                
                  userName : (item , index)=>{
                   
                     return (
                      <td style={{minWidth:'150px'}}>{item.userName}</td>
                     )
                  },
                  Action: (item, index) => {
                    return (
                      <td className="">
                        <div className="d-flex gap-3">
                       {item.status === 'Approved' ? <FaRegCheckSquare size={20} style={{cursor:"pointer"}} onClick={()=>approvedReview(item)} />: <FaCheckSquare size={20} style={{cursor:"pointer"}} onClick={()=>approvedReview(item)} /> }
                   
                       
                        <AiFillDelete style={{cursor:"pointer"}} size={20} onClick={()=>Delete_Category(item._id)}/>
                        </div>
                      </td>
                    );
                  },
                
                }}
              />
               <CPagination
               pages={totalpage}
              activePage={page}
              onActivePageChange={(i) => setPage(i)}
        />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
};

export default GetAllRating;

