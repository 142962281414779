import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CFormGroup,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CForm,
  CInput,
  CModalFooter,
  CRow,
} from "@coreui/react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getUserSupport, supportUpdateStatus } from "src/Redux/orderSlice";
import moment from "moment";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.min.css";
const { afterToday } = DateRangePicker;

const fields = [
  { key: "SL_NO", label: "SL No", _style: { minWidth: "100px" } },
  { key: "userName", label: "Name", _style: { width: "25%" } },
  { key: "userEmail", label: "Email", _style: { width: "10%" } },
  { key: "status", label: "Status", _style: { width: "10%" } },
  { key: "orderId", label: "Order Id", _style: { minWidth: "250px" } },
  { key: "subject", label: "Subject", _style: { width: "10%" } },
  { key: "message", label: "Messsage", _style: { minWidth: "300px" } },
  { key: "createdAt", label: "Created Date", _style: { width: "10%" } },
  { key: "remark", label: "Remark", _style: { width: "10%" } },
  { key: "Action", label: "Action", _style: { width: "10%" } },
];

const Support = () => {
  const dispatch = useDispatch();
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [filterDate, setFilterDate] = useState();
  const [searchkey, setSearchKey] = useState("");
  const [statusPopup, setstatusPopup] = useState(false);
  const [selectedSuuportId , setselectedSuuportId] = useState()
  const [changeorderstatuskey, setchangeorderstatuskey] = useState("");
  const support = useSelector((state) => state?.order?.support.data) || [];
  const totalpage =
    useSelector((state) => state?.order?.support.totalPage) || 1;
  const [remark, setRemark] = useState("");

  let data = {
    page: page,
    limits: itemsPerPage,
  };

  useEffect(() => {
    if (filterDate?.length === 2) {
      filterDate[1].setHours(23);
      filterDate[1].setMinutes(59);
      filterDate[1].setSeconds(59);
      data.ltDate = new Date(filterDate[1]);
      data.gtDate = filterDate[0].toDateString();
    }

    dispatch(getUserSupport(data));
  }, [page, itemsPerPage, filterDate]);

  useEffect(() => {
    // Only proceed if searchkey is not empty

    if (searchkey) {
      data.searchText = searchkey;
      if (filterDate?.length === 2) {
        filterDate[1].setHours(23);
        filterDate[1].setMinutes(59);
        filterDate[1].setSeconds(59);
        data.ltDate = new Date(filterDate[1]);
        data.gtDate = filterDate[0].toDateString();
      }

      // Set a timeout to dispatch after 2000 milliseconds (2 seconds)
      let timer = setTimeout(() => {
        dispatch(getUserSupport(data));
      }, 2000);

      // Cleanup the previous timer on each searchkey change
      return () => clearTimeout(timer);
    }

    // If searchkey is empty, don't dispatch and clean up any existing timers
    dispatch(getUserSupport(data));
    return () => {};
  }, [searchkey]);

  const HandleUpdate = (id) => {
    setchangeorderstatuskey('')
    setRemark('')
    setstatusPopup(true);
    setselectedSuuportId(id)
  };

  const HandleStatusChange = (e) => {
    setchangeorderstatuskey(e.target.value);
  };


  const updateStatus = () =>{

    let body = {
      _id : selectedSuuportId ,
      status : changeorderstatuskey
    }

    if(remark)
    {
      body.remark = remark
    }

    dispatch(supportUpdateStatus(body)).then(()=>{
      setstatusPopup(false);
      dispatch(getUserSupport(data));
    })
  }
  return (
    <>
      <div>
        <CRow>
          {/* // Status PopUp //  */}
          {statusPopup ? (
            <CModal
              show={statusPopup}
              onClose={() => {
                setstatusPopup(false);
              }}
              color="success"
            >
              <CModalHeader closeButton>
                <CModalTitle>Update Ticket Status</CModalTitle>
              </CModalHeader>

              <CModalBody>
                <CRow>
                  <CCol xs="12" md="12">
                    <CCard>
                      <CCardHeader> Update Ticket Status</CCardHeader>
                      <CCardBody>
                        <CForm
                          action=""
                          method="post"
                          encType="multipart/form-data"
                          className="form-horizontal"
                        >
                          <CFormGroup
                            row
                            className="my-0"
                            encType="multipart/form-data"
                          >
                            <CCol xs="12">
                              <InputLabel
                                id="demo-simple-select-arrival"
                                style={{ padding: "6px 15px" }}
                              >
                                Status
                              </InputLabel>
                              <FormControl
                                fullWidth
                                variant="outlined"
                                size="small"
                                style={{ margin: "0 12px", width: "94%" }}
                              >
                                <select
                                  className="form-select form-select-sm"
                                  aria-label=".form-select-sm example"
                                  onChange={HandleStatusChange}
                                  value={changeorderstatuskey}
                                >
                                  <option value="" disabled>
                                    Select
                                  </option>

                                  <option value="ACTIVE">ACTIVE</option>
                                  <option value="CLOSE">CLOSE</option>
                                </select>
                              </FormControl>
                            </CCol>

                            {changeorderstatuskey === "CLOSE" && (
                              <>
                                <CCol xs="12">
                                  <InputLabel
                                    id="demo-simple-select-arrival"
                                    style={{ padding: "15px 15px 0 15px" }}
                                  >
                                    Remark
                                  </InputLabel>
                                  <FormControl
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    style={{ margin: "0 12px", width: "94%" }}
                                  >
                                    <CInput
                                      name="remark"
                                      onChange={(e) =>
                                        setRemark(e.target.value)
                                      }
                                      value={remark}
                                    />
                                  </FormControl>
                                </CCol>
                              </>
                            )}
                          </CFormGroup>
                        </CForm>
                      </CCardBody>
                    </CCard>
                  </CCol>
                </CRow>
              </CModalBody>

              <CModalFooter>
                <CButton color="success" onClick={updateStatus}>Submit</CButton>
              </CModalFooter>
            </CModal>
          ) : null}

          <CCol>
            <CCard>
              <CCardHeader>Support</CCardHeader>
              <CCardBody>
                <div
                  style={{ position: "absolute", top: "71px", left: "18rem" }}
                >
                  <DateRangePicker
                    format="yyyy-MM-dd"
                    showOneCalendar
                    placeholder="Select Date Range"
                    value={filterDate}
                    onChange={(e) => setFilterDate(e)}
                    disabledDate={afterToday()}
                  />
                </div>
                <CDataTable
                  items={support}
                  fields={fields}
                  columnFilter={false}
                  tableFilter
                  footer={false}
                  itemsPerPageSelect
                  itemsPerPage={itemsPerPage}
                  hover
                  sorter
                  onPaginationChange={(e) => setItemsPerPage(e)}
                  onTableFilterChange={(item) => {
                    setSearchKey(item);
                  }}
                  scopedSlots={{
                    SL_NO: (item, index) => {
                      return <td style={{ minWidth: "100px" }}>{(page - 1) * itemsPerPage + index + 1}</td>;
                    },
                    createdAt: (item, index) => {
                      let date = moment(item.createdAt).format("ll");
                      return <td style={{ minWidth: "150px" }}>{date}</td>;
                    },
                    userName: (item, index) => {
                      return (
                        <td style={{ minWidth: "150px" }}>{item.userName}</td>
                      );
                    },
                    OrderId: (item, index) => {
                      return (
                        <td style={{ minWidth: "150px" }}>{item.OrderId}</td>
                      );
                    },
                    message: (item, index) => {
                      return (
                        <td style={{ minWidth: "300px" }}>{item.message}</td>
                      );
                    },
                    remark: (item, index) => {
                      return (
                        <td style={{ minWidth: "300px" }}>
                          {item?.remark || ""}
                        </td>
                      );
                    },

                    Action: (item) => {
                      return (
                        <td>
                          <CButton
                            //    onClick={() => handleClickOpen(item.products)}
                            onClick={() => HandleUpdate(item?._id)}
                            disabled={item.status === 'CLOSE'}
                            color="primary"
                            // variant="outlinPlee"
                            shape="square"
                            size="sm"
                          >
                            View
                          </CButton>
                        </td>
                      );
                    },
                  }}
                />
                <CPagination
                  pages={totalpage}
                  activePage={page}
                  onActivePageChange={(i) => setPage(i)}
                />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
    </>
  );
};

export default Support;
